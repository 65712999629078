import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import {
  Label,
  Grid,
  Form,
  Segment,
  Button,
  Header,
  Input as SemanticInput,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { blue } from 'theme'
import 'theme/slider.css'
import { updateGeneralFlatsInfo } from 'ducks/deviceActions'
import useIsMount from 'hooks/useIsMount'
import * as Yup from 'yup'
import { Formik, Field, Form as FormikForm } from 'formik'

const MyForm = styled(Form)``

const center = {
  display: 'flex',
  justifyContent: 'center',
}

const Input = (props) => <Field as={SemanticInput} {...props} />

const schema = Yup.object().shape({
  default_threshold_call: Yup.number()
    .min(1.0, 'Не может быть меньше 1.00')
    .max(12.0, 'Не может быть больше 12.00'),
  default_threshold_door: Yup.number()
    .min(1.0, 'Не может быть меньше 1.00')
    .max(12.0, 'Не может быть больше 12.00'),
})

const ResistanceLevels = ({ style = {}, ...props }) => {
  const isMount = useIsMount()
  const dispatch = useDispatch()

  const device = useSelector(({ device }) => device && device.shadowDevice)
  const baseConfig = device && device.properties && device.properties.base
  // const [modified, setModified] = useState(false)
  // const [level, setLevel] = useState(
  //   (baseConfig.adc_levels && baseConfig.adc_levels[2]) || defaultValue
  // )
  // useEffect(() => {
  //   if (!isMount) setModified(true)
  // }, [level])

  // const [{default_threshold_call,default_threshold_door}] = useState({
  //   default_threshold_door: baseConfig.default_threshold_door,
  //   default_threshold_call:baseConfig.default_threshold_call
  // })

  const handleSubmit = (values) => {
    // let newLevels = [...baseConfig.adc_levels]
    // newLevels.splice(-1, 1, level)
    console.log(values)
    dispatch(
      updateGeneralFlatsInfo({
        id: device.device_id,
        ...values,
        //        default_threshold_call:,
        // default_threshold_door:,
      })
    )
    // setModified(false)
  }

  const [showDefault, setShowDefault] = useState(
    baseConfig &&
      baseConfig?.default_threshold_call === 0.0 &&
      baseConfig?.default_threshold_door === 0.0
  )

  const handleReset = () => {
    dispatch(
      updateGeneralFlatsInfo({
        id: device.device_id,
        default_threshold_call: 0,
        default_threshold_door: 0,
      })
    )
    setShowDefault(true)
  }

  return (
    <Segment style={{ display: 'flex', flexDirection: 'column', ...style }}>
      <Header>Регулировка уровней событий</Header>

      {showDefault ? (
        <Form
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          Используются стандартные значения.
          <Button
            basic
            // icon="erase"
            content="Изменить"
            circular
            style={{ margin: '.625rem auto 0 0' }}
            onClick={() => setShowDefault(false)}
          />
        </Form>
      ) : (
        <Formik
          initialValues={{
            default_threshold_door: parseFloat(
              baseConfig.default_threshold_door || 9.99
            ).toFixed(2),
            default_threshold_call: parseFloat(
              baseConfig.default_threshold_call || 9.99
            ).toFixed(2),
          }}
          enableReinitialize
          validationSchema={schema}
          onSubmit={handleSubmit}>
          {({ errors }) => (
            <FormikForm
              className="ui form"
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}>
              <Form.Field>
                <label>Общий порог открытия двери</label>
                <Input
                  size="mini"
                  name="default_threshold_door"
                  type="number"
                  placeholder="по умолчанию 9.99 Ом"
                  fluid
                  step="0.01"
                />
                {errors?.['default_threshold_door'] && (
                  <Label basic color="red" pointing>
                    {errors?.['default_threshold_door']}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>Общий порог снятия трубки</label>
                <Input
                  size="mini"
                  name="default_threshold_call"
                  type="number"
                  placeholder="по умолчанию 9.99 Ом"
                  fluid
                  step="0.01"
                />
                {errors?.['default_threshold_call'] && (
                  <Label basic color="red" pointing>
                    {errors?.['default_threshold_call']}
                  </Label>
                )}
              </Form.Field>
              <div style={{ display: 'flex', marginTop: 'auto' }}>
                <Button
                  circular
                  color="black"
                  className="update-button"
                  type="submit"
                  disabled={Object.keys(errors).length > 0}
                  // disabled={!modified}
                >
                  Обновить
                </Button>
                <Button
                  basic
                  icon="erase"
                  content="Сбросить"
                  circular
                  onClick={handleReset}
                  // type="submit"
                  // style={{ marginRight: '.625rem' }}
                />
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </Segment>
  )
}

// const mapStateToProps = ({ device }) => ({
//   data:
//     device &&
//     device.shadowDevice &&
//     device.shadowDevice.properties &&
//     device.shadowDevice.properties.base,
//   id: device && device.shadowDevice && device.shadowDevice.device_id,
// })

// export default connect(mapStateToProps, { updateGeneralFlatsInfo })(
//   FlatsGeneral,
// )

export default ResistanceLevels
