import React from 'react'
import { List, Icon } from 'semantic-ui-react'

const ListItemStyle = {
  padding: '1.5rem',
}

const Key = ({ key_data, description, onClick, uuid, openCommentModal }) => {
  const [open, set] = React.useState(false)
  return (
    <List.Item style={ListItemStyle} onClick={() => openCommentModal(uuid)}>
      <List.Icon
        verticalAlign="middle"
        name="remove circle"
        onClick={(e) => {
          e.stopPropagation()
          onClick({ uuid, hex: key_data })
        }}
      />
      <List.Content>
        <List.Header>{key_data}</List.Header>
        <List.Description>{description}</List.Description>
      </List.Content>
    </List.Item>
  )
}
export default Key
