import React, { useState } from 'react'
import { Modal, Header, Button, Input, Form, Dropdown, Checkbox, Label } from 'semantic-ui-react'
import * as Yup from 'yup'
import { Formik, Field, Form as FormikForm } from 'formik'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { GET_VIRTUAL_FLATS } from 'gql/virtualFlats/query'
import { toastr } from 'react-redux-toastr'
import { GET_COMMON_FLATS } from 'gql/flats/query'
import { UPDATE_FLAT } from 'gql/flats/mutation'

const FormField = (props) => <Field as={Input} {...props} />

const MyForm = styled(Form)``

const schema = Yup.object().shape({
  thresholdCall: Yup.number()
    .min(1.0, 'Не может быть меньше 1.00')
    .max(12.0, 'Не может быть больше 12.00'),
  thresholdDoor: Yup.number()
    .nullable()
    .min(1.0, 'Не может быть меньше 1.00')
    .max(12.0, 'Не может быть больше 12.00'),
})

export default function FlatResistanceModal({ flat, open, onClose, isVirtual }) {
  const { id } = useParams()

  const reloadRequest = isVirtual ? GET_VIRTUAL_FLATS : GET_COMMON_FLATS
  const [updateFlats, info] = useMutation(UPDATE_FLAT, {
    refetchQueries: [
      {
        query: reloadRequest,
        variables: {
          input: {
            uuid: id,
          },
        },
      },
    ],
  })

  const handleSubmit = async (values) => {
    await updateFlats({
      variables: {
        input: {
          intercomID: id,
          flats: [
            {
              flatNum: flat?.num,
              parameters: {
                analogSettings: { ...flat.analogSettings, ...values },
                redirection: Boolean(flat.redirection),
                sipAccountContact: flat?.sipContact,
                soundVol: flat?.soundVol,
              },
            },
          ],
        },
      },
    }).then((el) => {
      onClose()
      toastr.success('Успех')
    })
  }

  const handleReset = () => {
    updateFlats({
      variables: {
        input: {
          intercomID: flat.uuid,
          flats: [
            {
              flatNum: flat?.num,
              parameters: {
                analogSettings: { ...flat.analogSettings, thresholdCall: 0 },
                redirection: Boolean(flat.redirection),
                sipAccountContact: flat?.sipContact,
                soundVol: flat?.soundVol,
              },
            },
          ],
        },
      },
    })
    setShowDefault(true)
  }

  const [showDefault, setShowDefault] = useState(
    !flat?.analogSettings ||
      (flat?.analogSettings?.thresholdCall === 0.0 && flat?.analogSettings?.thresholdDoor === 0.0)
  )

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Header content={`Квартира ${flat?.num}`} />
      <Modal.Content>
        <h4>Уровни сопротивлений</h4>
        {showDefault ? (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            Используются стандартные значения.
            <Button
              basic
              content="Изменить"
              circular
              style={{ margin: '.625rem auto 0 0' }}
              onClick={() => setShowDefault(false)}
            />
          </Form>
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              thresholdCall: parseFloat(flat?.analogSettings?.thresholdCall || 9.99).toFixed(2),
              thresholdDoor: isVirtual
                ? null
                : parseFloat(flat?.analogSettings?.thresholdDoor || 9.99).toFixed(2),
            }}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors }) => (
              <MyForm as={FormikForm}>
                {!isVirtual && (
                  <Form.Field>
                    <label>Порог открытия двери</label>
                    <FormField name="thresholdDoor" fluid step="0.01" type="number" />
                    {errors?.['thresholdDoor'] && (
                      <Label basic color="red" pointing>
                        {errors?.['thresholdDoor']}
                      </Label>
                    )}
                  </Form.Field>
                )}
                <Form.Field style={{ marginTop: '.625rem' }}>
                  <label>Порог снятия трубки</label>
                  <FormField name="thresholdCall" fluid step="0.01" type="number" />
                  {errors?.['thresholdCall'] && (
                    <Label basic color="red" pointing>
                      {errors?.['thresholdCall']}
                    </Label>
                  )}
                </Form.Field>

                <Modal.Actions
                  style={{
                    borderTop: '1px solid #d3d3d3',
                    marginTop: '1rem',
                    paddingTop: '1rem',
                  }}
                >
                  <Button onClick={onClose} circular>
                    Закрыть
                  </Button>

                  <Button
                    color="pink"
                    circular
                    type="submit"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Сохранить
                  </Button>

                  <Button
                    basic
                    icon="erase"
                    content="Вернуть стандартные"
                    circular
                    onClick={handleReset}
                    style={{ marginLeft: '.625rem' }}
                  />
                </Modal.Actions>
              </MyForm>
            )}
          </Formik>
        )}
      </Modal.Content>
    </Modal>
  )
}
