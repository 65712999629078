import React, { Component, useState } from 'react'
import { Button, Table, Icon, Dropdown, Input, Popup, Form, Modal, Header } from 'semantic-ui-react'
import { RIEInput } from 'riek'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'theme/slider.css'
import { pink } from 'theme'
import { ReactComponent as Phone } from 'assets/phone.svg'
import { ReactComponent as PhoneBlocked } from 'assets/phone_locked.svg'
import { ReactComponent as Sip } from 'assets/sip.svg'
import { ReactComponent as NoSip } from 'assets/nosip.svg'

const SliderWithTooltip = createSliderWithTooltip(Slider)

const Flat = ({ isVirtual, pendingLineTest, lineTest, ...props }) => {
  const [state, setState] = useState({
    redirection: props.flat.redirectionBool,
    sip_contact: props.flat.sipAccountContact,
    volume: props.flat.soundVol,
    modified: false,
    alias: props.flat?.analogSettings?.alias,
    confirmModalOpen: false,
    analogLineBlock: props.flat?.analogSettings?.blocked || false,
  })

  const onAnalogBlockToggle = (e) => {
    e.stopPropagation()
    setState({
      ...state,
      analogLineBlock: !state.analogLineBlock,
      modified: true,
    })
  }

  const onSIPChange = (e) => {
    setState({ ...state, modified: true, ...e })
  }

  const updateFlatInfo = () => {
    props.updateFlatInfo({
      uuid: props.id,
      num: Number.parseInt(props.flat.num, 10),
      redirection: state.redirection,
      sip_contact: state.sip_contact,
      sound_vol: state.volume,
      analog_settings: {
        alias: Number(state.alias),
        blocked: state.analogLineBlock,
      },
    })

    setState({ ...state, modified: false })
  }

  const onConfirm = () => {
    const { alias, modified } = state
    const { firstFlat, lastFlat } = props

    if (modified) {
      updateFlatInfo()
    }
  }

  const onVolumeChange = (volume) =>
    setState({
      ...state,
      volume,
      modified: true,
    })

  const handleModalClose = () => setState({ ...state, confirmModalOpen: false })

  const { redirection, sip_contact, modified, volume } = state

  return (
    <Table.Row warning={modified} style={{ cursor: 'pointer' }}>
      <Modal open={state.confirmModalOpen} onClose={handleModalClose} basic size="small">
        <Header icon="warning" content="Внимание" />
        <Modal.Content>
          <h3>Вы действительно хотите удалить квартиру?</h3>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleModalClose} inverted circular>
            Не удалять
          </Button>
          <Button
            onClick={(e) => {
              props.handleDeleteFlat()
              handleModalClose()
            }}
            color="red"
            circular
          >
            Удалить квартиру
          </Button>
        </Modal.Actions>
      </Modal>

      <Table.Cell collapsing textAlign="center" width={1}>
        {!props.isIP && (
          <>
            {!isVirtual && (
              <Popup
                trigger={
                  <Icon
                    name="bug"
                    onClick={(e) => {
                      e.stopPropagation()
                      lineTest({
                        deviceUuid: props.id,
                        flat: props.flat.num,
                      })
                    }}
                    disabled={pendingLineTest === props.flat.num}
                    color="pink"
                    style={{ marginLeft: 8 }}
                  />
                }
                content="Диагностика линии"
                position="top center"
              />
            )}
            <Popup
              trigger={
                <Icon name="bolt" onClick={props.onClick} color="pink" style={{ marginLeft: 8 }} />
              }
              content="Регулировка уровня событий"
              position="top center"
            />
            <Popup
              trigger={
                <i
                  onClick={onAnalogBlockToggle}
                  style={{ marginLeft: 8, height: 14, position: 'relative' }}
                  className={`${state.analogLineBlock ? 'grey' : 'pink'} icon`}
                >
                  {state.analogLineBlock ? (
                    <PhoneBlocked style={{ position: 'absolute', left: 0 }} />
                  ) : (
                    <Phone style={{ position: 'absolute', left: 0 }}></Phone>
                  )}
                </i>
              }
              content={
                state.analogLineBlock
                  ? 'Разблокировать аналоговую линию'
                  : 'Заблокировать аналоговую линию'
              }
              position="top center"
            />
            <Popup
              trigger={
                <i
                  onClick={() =>
                    setState({
                      ...state,
                      redirection: redirection === true ? false : true,
                      modified: true,
                    })
                  }
                  style={{ marginLeft: 8, height: 14, position: 'relative' }}
                  className={`${redirection === true ? 'pink' : 'grey'} icon`}
                >
                  {redirection === true ? (
                    <Sip
                      style={{
                        position: 'absolute',
                        left: 0,
                        height: 18,
                        width: 18,
                      }}
                    />
                  ) : (
                    <NoSip
                      style={{
                        position: 'absolute',
                        left: 0,
                        height: 18,
                        width: 18,
                      }}
                    ></NoSip>
                  )}
                </i>
              }
              content={redirection === false ? 'Разблокировать SIP' : 'Заблокировать SIP'}
              position="top center"
            />
            {/* <Table.Cell width={2}>
                <Dropdown
                  fluid
                  onChange={this.handleChange}
                  options={sipOptions}
                  placeholder="Переадресация"
                  selection
                  value={redirection}
                  size="mini"
                />
              </Table.Cell> */}
          </>
        )}

        {props.isIP && (
          <>
            <Icon
              name="trash"
              onClick={() => setState({ ...state, confirmModalOpen: true })}
              color="pink"
              style={{ marginLeft: 8 }}
            />
          </>
        )}
      </Table.Cell>
      <Table.Cell width={1} textAlign="center">
        №<strong>{props.flat.num}</strong>
      </Table.Cell>

      <Table.Cell width={3} textAlign="center">
        <RIEInput value={sip_contact || 'нет'} change={onSIPChange} propName="sip_contact" />
      </Table.Cell>

      {!props.isIP && (
        <>
          <Table.Cell width={4}>
            <SliderWithTooltip
              trackStyle={{ backgroundColor: pink }}
              value={volume}
              min={0}
              max={100}
              step={1}
              onChange={onVolumeChange}
            />
          </Table.Cell>
          <Table.Cell width={3}>
            <Input
              label="На линию"
              onChange={(e, { value }) => setState({ ...state, alias: value, modified: true })}
              placeholder={`Номер квартиры`}
              style={{ maxWidth: 80 }}
              type="number"
              size="mini"
              value={state.alias}
            />
          </Table.Cell>
        </>
      )}

      <Table.Cell width={3}>
        {modified && (
          <Button
            circular
            style={{ paddingLeft: '.625rem', paddingRight: '.625rem' }}
            floated="right"
            color="yellow"
            onClick={onConfirm}
          >
            Применить
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default Flat
