const { gql } = require('@apollo/client')

export const GET_COMMON_FLATS = gql`
  query GetNewFlats($input: IntercomFilter!) {
    intercom(input: $input) {
      uuid
      configShadow {
        flats {
          defaultThresholdCall
          defaultThresholdDoor
          newFlats(limit: 1000, filter: COMMON) {
            totalCount
            edges {
              node {
                num
                blocked
                redirectionBool
                soundVol
                sipAccountContact
                analogSettings {
                  alias
                  blocked
                  thresholdCall
                  thresholdDoor
                }
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_COMMON_FLATS = gql`
  mutation UpdateIntercomFlats($input: IntercomFlatsInput!) {
    updateIntercomFlats(input: $input) {
      notice
      success
    }
  }
`
