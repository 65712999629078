import { put, takeLatest, call } from 'redux-saga/effects'
import Api from 'api/sputnikApi'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadowWorker, getDeviceLogs } from './device'

const UPDATE_GENERAL_FLAT_INFO_REQUEST =
  'ducks/deviceActions/UPDATE_GENERAL_FLAT_INFO_REQUEST'
const UPDATE_GENERAL_FLAT_INFO_SUCCESS =
  'ducks/deviceActions/UPDATE_GENERAL_FLAT_INFO_SUCCESS'
const UPDATE_GENERAL_FLAT_INFO_FAILURE =
  'ducks/deviceActions/UPDATE_GENERAL_FLAT_INFO_FAILURE'

const OPEN_DOOR_REQUEST = 'ducks/deviceActions/OPEN_DOOR_REQUEST'
const OPEN_DOOR_SUCCESS = 'ducks/deviceActions/OPEN_DOOR_SUCCESS'
const OPEN_DOOR_FAILURE = 'ducks/deviceActions/OPEN_DOOR_FAILURE'

const OPEN_SECOND_DOOR_REQUEST = 'ducks/deviceActions/OPEN_SECOND_DOOR_REQUEST'

const UPDATE_SIP_CONFIG_REQUEST =
  'ducks/deviceActions/UPDATE_SIP_CONFIG_REQUEST'
const UPDATE_SIP_CONFIG_SUCCESS =
  'ducks/deviceActions/UPDATE_SIP_CONFIG_SUCCESS'
const UPDATE_SIP_CONFIG_FAILURE =
  'ducks/deviceActions/UPDATE_SIP_CONFIG_FAILURE'

const UPDATE_OPTIONAL_BUTTON_REQUEST =
  'ducks/deviceActions/UPDATE_OPTIONAL_BUTTON_REQUEST'
const UPDATE_OPTIONAL_BUTTON_SUCCESS =
  'ducks/deviceActions/UPDATE_OPTIONAL_BUTTON_SUCCESS'
const UPDATE_OPTIONAL_BUTTON_FAILURE =
  'ducks/deviceActions/UPDATE_OPTIONAL_BUTTON_FAILURE'

const UPLOAD_SOUND_REQUEST = 'ducks/deviceActions/UPLOAD_SOUND_REQUEST'
const UPLOAD_SOUND_SUCCESS = 'ducks/deviceActions/UPLOAD_SOUND_SUCCESS'
const UPLOAD_SOUND_FAILURE = 'ducks/deviceActions/UPLOAD_SOUND_FAILURE'

const UPDATE_SOUND_CONFIG_REQUEST =
  'ducks/deviceActions/UPDATE_SOUND_CONFIG_REQUEST'
const UPDATE_SOUND_CONFIG_SUCCESS =
  'ducks/deviceActions/UPDATE_SOUND_CONFIG_SUCCESS'
const UPDATE_SOUND_CONFIG_FAILURE =
  'ducks/deviceActions/UPDATE_SOUND_CONFIG_FAILURE'

const UPLOAD_FIRMWARE_REQUEST = 'ducks/deviceActions/UPLOAD_FIRMWARE_REQUEST'
const UPLOAD_FIRMWARE_SUCCESS = 'ducks/deviceActions/UPLOAD_FIRMWARE_SUCCESS'
const UPLOAD_FIRMWARE_FAILURE = 'ducks/deviceActions/UPLOAD_FIRMWARE_FAILURE'


const ADD_KEY_REQUEST = 'deviceActions/ADD_KEY_REQUEST'
const ADD_KEY_SUCCESS = 'deviceActions/ADD_KEY_SUCCESS'
const ADD_KEY_FAILURE = 'deviceActions/ADD_KEY_FAILURE'

const REMOVE_KEY_REQUEST = 'deviceActions/REMOVE_KEY_REQUEST'
export const REMOVE_KEY_SUCCESS = 'deviceActions/REMOVE_KEY_SUCCESS'
const REMOVE_KEY_FAILURE = 'deviceActions/REMOVE_KEY_FAILURE'

const UPDATE_DEVICE_SOUND_REQUEST = 'deviceActions/UPDATE_DEVICE_SOUND_REQUEST'
const UPDATE_DEVICE_SOUND_SUCCESS = 'deviceActions/UPDATE_DEVICE_SOUND_SUCCESS'
const UPDATE_DEVICE_SOUND_FAILURE = 'deviceActions/UPDATE_DEVICE_SOUND_FAILURE'

const START_EMERGENCY_REQUEST = 'deviceActions/START_EMERGENCY_REQUEST'
const START_EMERGENCY_SUCCESS = 'deviceActions/START_EMERGENCY_SUCCESS'
const START_EMERGENCY_FAILURE = 'deviceActions/START_EMERGENCY_FAILURE'

const RESET_INTERCOM_REQUEST = 'deviceActions/RESET_INTERCOM_REQUEST'
const RESET_INTERCOM_SUCCESS = 'deviceActions/RESET_INTERCOM_SUCCESS'
const RESET_INTERCOM_FAILURE = 'deviceActions/RESET_INTERCOM_FAILURE'

const RESET_DEVICE_SOUNDS_REQUEST = 'device/RESET_DEVICE_SOUNDS_REQUEST'
const RESET_DEVICE_SOUNDS_SUCCESS = 'device/RESET_DEVICE_SOUNDS_SUCCESS'
const RESET_DEVICE_SOUNDS_FAILURE = 'device/RESET_DEVICE_SOUNDS_FAILURE'

const UPDATE_STANDART_SOUND_REQUEST =
  'deviceActions/UPDATE_STANDART_SOUND_REQUEST'
const UPDATE_STANDART_SOUND_SUCCESS =
  'deviceActions/UPDATE_STANDART_SOUND_SUCCESS'
const UPDATE_STANDART_SOUND_FAILURE =
  'deviceActions/UPDATE_STANDART_SOUND_FAILURE'

const UPDATE_NETWORK_INFO_REQUEST = 'deviceActions/UPDATE_NETWORK_INFO_REQUEST'
const UPDATE_NETWORK_INFO_SUCCESS = 'deviceActions/UPDATE_NETWORK_INFO_SUCCESS'
const UPDATE_NETWORK_INFO_FAILURE = 'deviceActions/UPDATE_NETWORK_INFO_FAILURE'

const UPDATE_DOOR_OPEN_TIMER_REQUEST =
  'deviceActions/UPDATE_DOOR_OPEN_TIMER_REQUEST'
const UPDATE_DOOR_OPEN_TIMER_SUCCESS =
  'deviceActions/UPDATE_DOOR_OPEN_TIMER_SUCCESS'
const UPDATE_DOOR_OPEN_TIMER_FAILURE =
  'deviceActions/UPDATE_DOOR_OPEN_TIMER_FAILURE'

const GET_DEVICE_KEYS_COUNT_REQUEST =
  'deviceActions/GET_DEVICE_KEYS_COUNT_REQUEST'
const GET_DEVICE_KEYS_COUNT_SUCCESS =
  'deviceActions/GET_DEVICE_KEYS_COUNT_SUCCESS'
const GET_DEVICE_KEYS_COUNT_FAILURE =
  'deviceActions/GET_DEVICE_KEYS_COUNT_FAILURE'

const DOWNLOAD_KEYS_REQUEST = 'deviceActions/DOWNLOAD_KEYS_REQUEST'
const DOWNLOAD_KEYS_SUCCESS = 'deviceActions/DOWNLOAD_KEYS_SUCCESS'
const DOWNLOAD_KEYS_FAILURE = 'deviceActions/DOWNLOAD_KEYS_FAILURE'

const RESET_DEVICE_CAMERA_REQUEST = 'deviceActions/RESET_DEVICE_CAMERA_REQUEST'
const RESET_DEVICE_CAMERA_SUCCESS = 'deviceActions/RESET_DEVICE_CAMERA_SUCCESS'
const RESET_DEVICE_CAMERA_FAILURE = 'deviceActions/RESET_DEVICE_CAMERA_FAILURE'

const ADD_ACCESS_CODE_REQUEST = 'deviceActions/ADD_ACCESS_CODE_REQUEST'
const ADD_ACCESS_CODE_SUCCESS = 'deviceActions/ADD_ACCESS_CODE_SUCCESS'
const ADD_ACCESS_CODE_FAILURE = 'deviceActions/ADD_ACCESS_CODE_FAILURE'

const DELETE_ACCESS_CODE_REQUEST = 'deviceActions/DELETE_ACCESS_CODE_REQUEST'
const DELETE_ACCESS_CODE_SUCCESS = 'deviceActions/DELETE_ACCESS_CODE_SUCCESS'
const DELETE_ACCESS_CODE_FAILURE = 'deviceActions/DELETE_ACCESS_CODE_FAILURE'

//factoryReset
const FACTORY_RESET_REQUEST = 'deviceActions/FACTORY_RESET_REQUEST'
const FACTORY_RESET_SUCCESS = 'deviceActions/FACTORY_RESET_SUCCESS'
const FACTORY_RESET_FAILURE = 'deviceActions/FACTORY_RESET_FAILURE'

const GET_DEVICE_ACCESS_CODES_REQUEST =
  'deviceActions/GET_DEVICE_ACCESS_CODES_REQUEST'
const GET_DEVICE_ACCESS_CODES_SUCCESS =
  'deviceActions/GET_DEVICE_ACCESS_CODES_SUCCESS'
const GET_DEVICE_ACCESS_CODES_FAILURE =
  'deviceActions/GET_DEVICE_ACCESS_CODES_FAILURE'

const GET_NET_INFO_REQUEST = 'deviceActions/GET_NET_INFO_REQUEST'
const GET_NET_INFO_SUCCESS = 'deviceActions/GET_NET_INFO_SUCCESS'
const GET_NET_INFO_FAILURE = 'deviceActions/GET_NET_INFO_FAILURE'

const GET_URL_INFO_REQUEST = 'deviceActions/GET_URL_INFO_REQUEST'
const GET_URL_INFO_SUCCESS = 'deviceActions/GET_URL_INFO_SUCCESS'
const GET_URL_INFO_FAILURE = 'deviceActions/GET_URL_INFO_FAILURE'

const LINE_TEST_REQUEST = 'deviceActions/LINE_TEST_REQUEST'
const LINE_TEST_SUCCESS = 'deviceActions/LINE_TEST_SUCCESS'
const LINE_TEST_FAILURE = 'deviceActions/LINE_TEST_FAILURE'

const TOGGLE_MODE_REQUEST = 'deviceActions/TOGGLE_MODE_REQUEST'
const TOGGLE_MODE_SUCCESS = 'deviceActions/TOGGLE_MODE_SUCCESS'
const TOGGLE_MODE_FAILURE = 'deviceActions/TOGGLE_MODE_FAILURE'


const DELETE_ALL_FLATS_REQUEST = 'deviceActions/DELETE_ALL_FLATS_REQUEST'
const DELETE_ALL_FLATS_SUCCESS = 'deviceActions/DELETE_ALL_FLATS_SUCCESS'
const DELETE_ALL_FLATS_FAILURE = 'deviceActions/DELETE_ALL_FLATS_FAILURE'

const UPDATE_CODE_COMMENT_REQUEST = 'deviceActions/UPDATE_CODE_COMMENT_REQUEST'
const UPDATE_CODE_COMMENT_SUCCESS = 'deviceActions/UPDATE_CODE_COMMENT_SUCCESS'
const UPDATE_CODE_COMMENT_FAILURE = 'deviceActions/UPDATE_CODE_COMMENT_FAILURE'

const UPLOAD_CODES_BUTCH_REQUEST = 'deviceActions/UPLOAD_CODES_BUTCH_REQUEST'
const UPLOAD_CODES_BUTCH_SUCCESS = 'deviceActions/UPLOAD_CODES_BUTCH_SUCCESS'
const UPLOAD_CODES_BUTCH_FAILURE = 'deviceActions/UPLOAD_CODES_BUTCH_FAILURE'

const DOWNLOAD_CODES_REQUEST = 'deviceActions/DOWNLOAD_CODES_REQUEST'
const DOWNLOAD_CODES_SUCCESS = 'deviceActions/DOWNLOAD_CODES_SUCCESS'

const DOWNLOAD_CODES_LOADER_REQUEST = 'deviceActions/DOWNLOAD_CODES_REQUEST'
const DOWNLOAD_CODES_LOADER_SUCCESS = 'deviceActions/DOWNLOAD_CODES_SUCCESS'

const DOWNLOAD_KEYS_LOADER_REQUEST =
  'deviceActions/DOWNLOAD_KEYS_LOADER_REQUEST'
const DOWNLOAD_KEYS_LOADER_SUCCESS =
  'deviceActions/DOWNLOAD_KEYS_LOADER_SUCCESS'

const DELETE_ALL_ACCESS_CODES_REQUEST =
  'deviceActions/DELETE_ALL_ACCESS_CODES_REQUEST'
const DELETE_ALL_ACCESS_CODES_SUCCESS =
  'deviceActions/DELETE_ALL_ACCESS_CODES_SUCCESS'
const DELETE_ALL_ACCESS_CODES_FAILURE =
  'deviceActions/DELETE_ALL_ACCESS_CODES_FAILURE'

const UPDATE_TIMEZONE_REQUEST = 'deviceActions/UPDATE_TIMEZONE_REQUEST'
const UPDATE_TIMEZONE_SUCCESS = 'deviceActions/UPDATE_TIMEZONE_SUCCESS'
const UPDATE_TIMEZONE_FAILURE = 'deviceActions/UPDATE_TIMEZONE_FAILURE'

const UPLOADING_CODES_REQUEST = 'UPLOADING_CODES_REQUEST'
const UPLOADING_CODES_SUCCESS = 'UPLOADING_CODES_SUCCESS'

const CLEAR_DEVICE_STATUS = 'deviceActions/CLEAR_DEVICE_STATUS'

const initialState = {
  error: '',
  success: '',
  upLoadingCodes: false,
}

// reducer
export default function deviceActions(state = initialState, action) {
  switch (action.type) {
    case GET_DEVICE_ACCESS_CODES_SUCCESS:
      return { ...state, accessCodes: action.payload.accessCodes }

    case UPDATE_SIP_CONFIG_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      }
    case UPDATE_SIP_CONFIG_FAILURE:
      return { ...state, error: action.payload }
    case OPEN_DOOR_SUCCESS:
      return { ...state, error: '', success: action.payload }
    case OPEN_DOOR_FAILURE:
      return { ...state, error: action.payload }
    case CLEAR_DEVICE_STATUS:
      return { ...state, success: '', error: '' }
    case UPLOAD_SOUND_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case UPLOAD_SOUND_FAILURE:
      return { ...state, success: '', error: action.payload }
    case UPLOAD_FIRMWARE_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case UPLOAD_FIRMWARE_FAILURE:
      return { ...state, success: '', error: action.payload }
    case UPDATE_GENERAL_FLAT_INFO_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      }
    case UPDATE_GENERAL_FLAT_INFO_FAILURE:
      return { ...state, error: action.payload }
    case UPDATE_SOUND_CONFIG_SUCCESS:
      return { ...state, error: '', success: action.payload }
    case UPDATE_SOUND_CONFIG_FAILURE:
      return { ...state, success: '', error: action.payload }
    case ADD_KEY_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case ADD_KEY_FAILURE:
      return { ...state, error: action.payload }
    case REMOVE_KEY_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case REMOVE_KEY_FAILURE:
      return { ...state, error: action.payload }
    case UPDATE_DEVICE_SOUND_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case UPDATE_DEVICE_SOUND_FAILURE:
      return { ...state, error: action.payload }
    case UPDATE_STANDART_SOUND_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      }
    case UPDATE_STANDART_SOUND_FAILURE:
      return { ...state, error: action.payload }
    case START_EMERGENCY_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      }
    case START_EMERGENCY_FAILURE:
      return { ...state, error: action.payload }
    case UPDATE_NETWORK_INFO_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      }
    case UPDATE_NETWORK_INFO_FAILURE:
      return { ...state, error: action.payload }
    case UPDATE_DOOR_OPEN_TIMER_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case UPDATE_DOOR_OPEN_TIMER_FAILURE:
      return { ...state, error: action.payload }
    case RESET_INTERCOM_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      }
    case RESET_INTERCOM_FAILURE:
      return { ...state, error: action.payload }
    case RESET_DEVICE_SOUNDS_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      }
    case RESET_DEVICE_SOUNDS_FAILURE:
      return { ...state, error: action.payload }
    case GET_DEVICE_KEYS_COUNT_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case GET_DEVICE_KEYS_COUNT_FAILURE:
      return { ...state, error: action.payload }
    case RESET_DEVICE_CAMERA_SUCCESS:
      return { ...state, success: action.payload, error: '' }
    case RESET_DEVICE_CAMERA_FAILURE:
      return { ...state, error: action.payload }
    case LINE_TEST_REQUEST:
      return { ...state, pendingLineTest: action.payload.flat }
    case LINE_TEST_SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: '',
        pendingLineTest: null,
      }
    case LINE_TEST_FAILURE:
      return { ...state, error: action.payload, pendingLineTest: null }

    case DOWNLOAD_CODES_LOADER_REQUEST:
      return { ...state, loadingCodes: true }
    case DOWNLOAD_CODES_LOADER_SUCCESS:
      return { ...state, loadingCodes: false }
    case DOWNLOAD_KEYS_LOADER_REQUEST:
      return { ...state, loadingKeys: true }
    case DOWNLOAD_KEYS_LOADER_SUCCESS:
      return { ...state, loadingKeys: false }
    case DELETE_ALL_ACCESS_CODES_REQUEST: {
      return { ...state }
    }
    case DELETE_ALL_ACCESS_CODES_SUCCESS: {
      return { ...state, accessCodes: action.payload }
    }
    case DELETE_ALL_ACCESS_CODES_FAILURE: {
      return { ...state, error: action.payload }
    }

    case UPLOADING_CODES_REQUEST: {
      return { ...state, upLoadingCodes: true }
    }
    case UPLOADING_CODES_SUCCESS: {
      return { ...state, upLoadingCodes: false }
    }

    default:
      return state
  }
}

// action creators

export const resetDeviceSounds = (id) => ({
  type: RESET_DEVICE_SOUNDS_REQUEST,
  payload: {
    id,
  },
})

export const openDoor = (payload) => ({
  type: OPEN_DOOR_REQUEST,
  payload: payload,
})

export const openSecondDoor = (payload) => ({
  type: OPEN_SECOND_DOOR_REQUEST,
  payload: payload,
})

export const startEmergency = (payload) => ({
  type: START_EMERGENCY_REQUEST,
  payload: payload,
})

export const factoryReset = (payload) => ({
  type: FACTORY_RESET_REQUEST,
  payload: payload,
})

export const resetIntercom = (payload) => ({
  type: RESET_INTERCOM_REQUEST,
  payload: payload,
})

export const updateGeneralFlatsInfo = (payload) => ({
  type: UPDATE_GENERAL_FLAT_INFO_REQUEST,
  payload,
})

export const updateSoundConfig = (payload) => ({
  type: UPDATE_SOUND_CONFIG_REQUEST,
  payload,
})

export const updateNetworkInfo = (payload) => ({
  type: UPDATE_NETWORK_INFO_REQUEST,
  payload,
})

export const updateDoorOpenTimers = (payload) => ({
  type: UPDATE_DOOR_OPEN_TIMER_REQUEST,
  payload,
})

export const updateStandartSounds = (payload) => ({
  type: UPDATE_STANDART_SOUND_REQUEST,
  payload,
})

export const updateSIPConfig = (payload) => ({
  type: UPDATE_SIP_CONFIG_REQUEST,
  payload,
})

export const updateOptionalButtonConfig = (payload) => ({
  type: UPDATE_OPTIONAL_BUTTON_REQUEST,
  payload,
})

export const clearDeviceStatus = () => ({
  type: CLEAR_DEVICE_STATUS,
})

export const uploadSound = (payload) => ({
  type: UPLOAD_SOUND_REQUEST,
  payload,
})

export const uploadFirmware = (payload) => ({
  type: UPLOAD_FIRMWARE_REQUEST,
  payload,
})


export const lineTest = (payload) => ({
  type: LINE_TEST_REQUEST,
  payload,
})

export const addKey = (payload) => ({
  type: ADD_KEY_REQUEST,
  payload,
})

export const removeKey = (payload) => ({
  type: REMOVE_KEY_REQUEST,
  payload,
})

export const downloadKeys = (uuid) => ({
  type: DOWNLOAD_KEYS_REQUEST,
  payload: { uuid },
})

export const updateDeviceSounds = (payload) => ({
  type: UPDATE_DEVICE_SOUND_REQUEST,
  payload,
})

export const resetDeviceCamera = (uuid) => ({
  type: RESET_DEVICE_CAMERA_REQUEST,
  payload: {
    uuid,
  },
})

export const getDeviceAccessCodes = (deviceUuid) => ({
  type: GET_DEVICE_ACCESS_CODES_REQUEST,
  payload: {
    deviceUuid,
  },
})

export const addAccessCode = ({ deviceUuid, key, expTime, description }) => ({
  type: ADD_ACCESS_CODE_REQUEST,
  payload: {
    deviceUuid,
    key,
    expTime,
    description,
  },
})

export const deleteAccessCode = ({ deviceUuid, key }) => ({
  type: DELETE_ACCESS_CODE_REQUEST,
  payload: {
    deviceUuid,
    key,
  },
})

export const getNetInfo = ({ deviceUuid, logsQty, activePage }) => ({
  type: GET_NET_INFO_REQUEST,
  payload: {
    deviceUuid,
    logsQty,
    activePage,
  },
})

export const getURLInfo = ({ deviceUuid, logsQty, activePage }) => ({
  type: GET_URL_INFO_REQUEST,
  payload: {
    deviceUuid,
    logsQty,
    activePage,
  },
})

export const getDeviceKeysCount = ({ deviceUuid, logsQty, activePage }) => ({
  type: GET_DEVICE_KEYS_COUNT_REQUEST,
  payload: {
    deviceUuid,
    logsQty,
    activePage,
  },
})

export const toggleMode = (deviceUuid) => ({
  type: TOGGLE_MODE_REQUEST,
  payload: deviceUuid,
})



export const deleteAllFlats = (deviceUuid) => ({
  type: DELETE_ALL_FLATS_REQUEST,
  payload: deviceUuid,
})

export const updateCodeComment = (intercomUuid, params) => ({
  type: UPDATE_CODE_COMMENT_REQUEST,
  payload: { intercomUuid, params },
})

export const updateTimeZone = (uuid, zone) => ({
  type: UPDATE_TIMEZONE_REQUEST,
  payload: { uuid, zone },
})

export const uploadCodesButch = (intercomUuid, file) => ({
  type: UPLOAD_CODES_BUTCH_REQUEST,
  payload: { intercomUuid, file },
})

export const downloadCodes = (intercomUuid) => ({
  type: DOWNLOAD_CODES_REQUEST,
  payload: { intercomUuid },
})

export const deleteAllAccessCodes = (intercomUuid) => ({
  type: DELETE_ALL_ACCESS_CODES_REQUEST,
  payload: intercomUuid,
})

// sagas

function* updateTimeZoneWorker({ type, payload }) {
  try {
    yield call(Api.updateTimeZone, payload)
    yield put({
      type: UPDATE_TIMEZONE_SUCCESS,
    })
    toastr.success('Зона успешна изменена')
    return Promise.resolve()
  } catch (err) {
    yield put({
      type: UPDATE_TIMEZONE_FAILURE,
    })
    toastr.error('При изменении зоны произошла ошибка')
    return Promise.reject()
  }
}

function* factoryResetWorker({ type, payload }) {
  try {
    yield call(Api.factoryReset, payload)
    yield put({
      type: FACTORY_RESET_SUCCESS,
    })
    toastr.success('Настройки сброшены')
    return Promise.resolve()
  } catch (err) {
    yield put({
      type: FACTORY_RESET_FAILURE,
    })
    toastr.error('При сбросе настроек произошла ошибка')
    return Promise.reject()
  }
}

function* deleteAllAccessCodesWorker({ type, payload }) {
  try {
    yield call(Api.deleteAllAccessCodes, payload)
    yield put({
      type: DELETE_ALL_ACCESS_CODES_SUCCESS,
      payload: [],
    })
    toastr.success('Коды успешно удалены')
    return Promise.resolve()
  } catch (err) {
    yield put({
      type: DELETE_ALL_ACCESS_CODES_FAILURE,
    })
    toastr.error('При удалении кодов произошла ошибка')
    return Promise.reject()
  }
}

function* deleteAccessCodeWorker({ type, payload }) {
  try {
    yield call(Api.deleteAccessCode, payload)
    yield put({
      type: GET_DEVICE_ACCESS_CODES_REQUEST,
      payload: { deviceUuid: payload.deviceUuid },
    })
    yield put({
      type: DELETE_ACCESS_CODE_SUCCESS,
    })

    toastr.success('Код доступа был успешно удален')

    return Promise.resolve()
  } catch (err) {
    yield put({
      type: DELETE_ACCESS_CODE_FAILURE,
    })
    toastr.error('При удалении кода доступ произошла ошибка')
    return Promise.reject()
  }
}

function* addAccessCodeWorker({ type, payload }) {
  try {
    yield call(Api.addAccessCode, payload)
    yield put({
      type: GET_DEVICE_ACCESS_CODES_REQUEST,
      payload: { deviceUuid: payload.deviceUuid },
    })
    yield put({
      type: ADD_ACCESS_CODE_SUCCESS,
    })

    toastr.success('Код досупа был успешно добавлен')

    return Promise.resolve()
  } catch (err) {
    yield put({
      type: ADD_ACCESS_CODE_FAILURE,
    })
    toastr.error('При добавлении кода доступа произошла ошибка')
    return Promise.reject()
  }
}

function* getDeviceAccessCodesWorker({ type, payload }) {
  try {
    const { data } = yield call(Api.getDeviceAccessCodes, payload)
    yield put({
      type: GET_DEVICE_ACCESS_CODES_SUCCESS,
      payload: { accessCodes: data },
    })
    return Promise.resolve()
  } catch (err) {
    yield put({
      type: GET_DEVICE_ACCESS_CODES_FAILURE,
      payload: 'При получении списка кодов произошла ошибка',
    })
    return Promise.reject()
  }
}

function* resetDeviceSoundsWorker({ type, payload }) {
  try {
    yield call(Api.resetDeviceSounds, payload)
    yield put({
      type: RESET_DEVICE_SOUNDS_SUCCESS,
      payload: 'Были восстановлены стандартные звуки',
    })
    return Promise.resolve()
  } catch (err) {
    yield put({
      type: RESET_DEVICE_SOUNDS_FAILURE,
      payload: 'При восстановлении стандартных звуков произошла ошибка',
    })
    return Promise.reject()
  }
}

function* updateGeneralFlatsInfoWorker({ type, payload }) {
  try {
    yield call(Api.updateGeneralFlatsInfo, payload)
    yield put({
      type: UPDATE_GENERAL_FLAT_INFO_SUCCESS,
      payload: 'Информация обновлена.',
    })
  } catch (err) {
    yield put({ type: UPDATE_GENERAL_FLAT_INFO_FAILURE, payload: err.message })
  }
}

function* getNetInfoWorker({ type, payload }) {
  try {
    yield call(Api.getNetInfo, payload)
    yield put({
      type: GET_NET_INFO_SUCCESS,
    })
    yield put(
      getDeviceLogs(payload.deviceUuid, payload.logsQty, payload.activePage)
    )
  } catch (err) {
    yield put({ type: GET_NET_INFO_FAILURE })
    toastr.error(
      'Что-то пошло не так!',
      'При запросе сетевых настроек произошла ошибка'
    )
  }
}

function* getURLInfoWorker({ type, payload }) {
  try {
    yield call(Api.getURLInfo, payload)
    yield put({
      type: GET_URL_INFO_SUCCESS,
    })
    yield put(
      getDeviceLogs(payload.deviceUuid, payload.logsQty, payload.activePage)
    )
  } catch (err) {
    yield put({ type: GET_URL_INFO_FAILURE })
    toastr.error('Что-то пошло не так!', 'При запросе URL произошла ошибка')
  }
}

function* getDeviceKeysCountWorker({ type, payload }) {
  try {
    yield call(Api.getDeviceKeysCount, payload)
    yield put({
      type: GET_DEVICE_KEYS_COUNT_SUCCESS,
      payload: 'Перейдите в логи для просмотра количества ключей',
    })
    yield put(
      getDeviceLogs(payload.deviceUuid, payload.logsQty, payload.activePage)
    )
  } catch (err) {
    yield put({
      type: GET_DEVICE_KEYS_COUNT_FAILURE,
      payload: 'При получении количества ключей произошла ошибка',
    })
  }
}

function* updateSoundConfigWorker({ type, payload }) {
  try {
    yield call(Api.updateSoundConfig, payload)
    yield put({
      type: UPDATE_SOUND_CONFIG_SUCCESS,
      payload: 'Информация обновлена.',
    })
  } catch (err) {
    yield put({ type: UPDATE_SOUND_CONFIG_FAILURE, payload: err.message })
  }
}

function* updateNetworkInfoWorker({ type, payload }) {
  try {
    yield call(Api.updateNetworkInfo, payload)
    yield put({
      type: UPDATE_NETWORK_INFO_SUCCESS,
      payload: 'Информация обновлена.',
    })
  } catch (err) {
    yield put({ type: UPDATE_NETWORK_INFO_FAILURE, payload: err.message })
  }
}

function* updateDoorOpenTimerWorker({ type, payload }) {
  try {
    yield call(Api.updateDoorOpenTimer, payload)
    yield put({
      type: UPDATE_DOOR_OPEN_TIMER_SUCCESS,
      payload: 'Информация обновлена.',
    })
  } catch (err) {
    yield put({ type: UPDATE_DOOR_OPEN_TIMER_FAILURE, payload: err.message })
  }
}

function* openDoorWorker({ type, payload }) {
  try {
    yield call(Api.openDoor, payload)
    yield put({ type: OPEN_DOOR_SUCCESS, payload: 'Дверь открыта.' })
  } catch (err) {
    yield put({ type: OPEN_DOOR_FAILURE, payload: 'Не удалось открыть дверь.' })
  }
}

function* openSecondDoorWorker({ type, payload }) {
  try {
    yield call(Api.openSecondDoor, payload)
    yield put({ type: OPEN_DOOR_SUCCESS, payload: 'Дверь открыта.' })
  } catch (err) {
    yield put({ type: OPEN_DOOR_FAILURE, payload: 'Не удалось открыть дверь.' })
  }
}

function* resetIntercomWorker({ type, payload }) {
  try {
    yield call(Api.resetIntercom, payload)
    yield put({
      type: RESET_INTERCOM_SUCCESS,
      payload: 'Запрос на перезагрузку отправлен.',
    })
  } catch (err) {
    yield put({
      type: RESET_INTERCOM_FAILURE,
      payload: 'Не удалось отправить запрос на обновление.',
    })
  }
}

function* startEmergencyWorker({ type, payload }) {
  try {
    yield call(Api.startEmergency, payload)
    yield put({
      type: START_EMERGENCY_SUCCESS,
      payload: 'Оповещение запущено.',
    })
    return Promise.resolve()
  } catch (err) {
    yield put({
      type: START_EMERGENCY_FAILURE,
      payload: 'Не удалось запустить оповещение.',
    })
    return Promise.reject()
  }
}

function* updateStandartSoundWorker({ type, payload }) {
  try {
    yield call(Api.updateStandartSounds, payload)
    yield put({
      type: UPDATE_STANDART_SOUND_SUCCESS,
      payload: 'Звук обновлен.',
    })
  } catch (err) {
    yield put({
      type: UPDATE_STANDART_SOUND_FAILURE,
      payload: 'Не удалось обновить звук.',
    })
  }
}

function* updateSIPConfigWorker({ type, payload }) {
  try {
    yield call(Api.updateSIPConfig, payload)
    yield put({
      type: UPDATE_SIP_CONFIG_SUCCESS,
      payload: 'Конфигурация обновлена.',
    })
  } catch (err) {
    yield put({
      type: UPDATE_SIP_CONFIG_FAILURE,
      payload: 'При обновлении данных произошла ошибка.',
    })
  }
}

function* updateOptionalButtonConfigWorker({ type, payload }) {
  try {
    yield call(Api.updateOptionalButtonConfig, payload)
    yield put({
      type: UPDATE_SIP_CONFIG_SUCCESS,
      payload: 'Конфигурация обновлена.',
    })
  } catch (err) {
    yield put({
      type: UPDATE_SIP_CONFIG_FAILURE,
      payload: 'При обновлении данных произошла ошибка.',
    })
  }
}

function* uploadSoundWorker({ type, payload }) {
  try {
    yield call(Api.uploadSound, payload)
    yield put({
      type: UPLOAD_SOUND_SUCCESS,
      payload: 'Файл загружен.',
    })
  } catch (err) {
    const errors = err.response.data.errors.url //TODO на бэкенде когда исправят, тут тоже поправить надо
    yield put({
      type: UPLOAD_SOUND_FAILURE,
      payload: errors,
    })
  }
}

function* uploadFirmwareWorker({ type, payload }) {
  try {
    yield call(Api.uploadFirmware, payload)
    yield put({
      type: UPLOAD_FIRMWARE_SUCCESS,
      payload: 'Файл загружен.',
    })
  } catch (err) {
    const errors = err.response.data.errors
    yield put({
      type: UPLOAD_FIRMWARE_FAILURE,
      payload: errors,
    })
  }
}


function* addKeyWorker({ type, payload }) {
  try {
    yield call(Api.addKey, payload)
    yield put({
      type: ADD_KEY_SUCCESS,
      payload: 'Ключ добавлен.',
    })
  } catch (err) {
    console.log(err)
    yield put({
      type: ADD_KEY_FAILURE,
      payload: 'При добавлении ключа произошла ошибка.',
    })
  }
}

function* removeKeyWorker({ type, payload }) {
  try {
    yield call(Api.removeKey, payload)
    yield put({
      type: REMOVE_KEY_SUCCESS,
      payload: {
        intercomUuid: payload.uuid,
      },
    })
  } catch (err) {
    console.log(err)
    yield put({
      type: REMOVE_KEY_FAILURE,
      payload: 'При удалении ключа произошла ошибка.',
    })
  }
}

function* updateDeviceSoundWorker({ type, payload }) {
  try {
    yield call(Api.updateDeviceSounds, payload)
    yield put({
      type: UPDATE_DEVICE_SOUND_SUCCESS,
      payload: 'Успешно',
    })
  } catch (err) {
    console.log(err)
    yield put({
      type: UPDATE_DEVICE_SOUND_FAILURE,
      payload: 'При обновлении возникла ошибка.',
    })
  }
}

function* downloadKeysWorker({ type, payload }) {
  try {
    yield put({ type: DOWNLOAD_KEYS_LOADER_REQUEST })
    yield call(Api.downloadKeys, payload)
    yield put({
      type: GET_DEVICE_KEYS_COUNT_SUCCESS,
    })
    yield put({ type: DOWNLOAD_KEYS_LOADER_SUCCESS })
  } catch (err) {
    yield put({
      type: GET_DEVICE_KEYS_COUNT_FAILURE,
    })
    yield put({ type: DOWNLOAD_KEYS_LOADER_SUCCESS })
  }
}

function* resetDeviceCameraWorker({ type, payload }) {
  try {
    yield call(Api.resetDeviceCamera, payload)
    yield put({
      type: RESET_DEVICE_CAMERA_SUCCESS,
      payload: 'Камера была успешно перезапущена',
    })
  } catch (err) {
    yield put({
      type: RESET_DEVICE_CAMERA_FAILURE,
      payload: 'При получении перезагрузке камеры произошла ошибка',
    })
  }
}

function* updateAdcLevelsWorker({ type, payload }) {
  // try {
  //   yield call(Api.resetDeviceCamera, payload)
  //   yield put({
  //     type: RESET_DEVICE_CAMERA_SUCCESS,
  //     payload: 'Камера была успешно перезапущена',
  //   })
  // } catch (err) {
  //   yield put({
  //     type: RESET_DEVICE_CAMERA_FAILURE,
  //     payload: 'При получении перезагрузке камеры произошла ошибка',
  //   })
  // }
}

function* lineTestWorker({ type, payload }) {
  try {
    const response = yield call(Api.lineTest, payload)
    yield put({
      type: LINE_TEST_SUCCESS,
      payload: `
      Квартира ${response.data.flat}
      Вольтаж ${response.data.com_line_voltage}
      Статус ${response.data.status}
      `,
    })
  } catch (err) {
    console.log(err)
    yield put({
      type: LINE_TEST_FAILURE,
      payload: `Не удалось запустить проверку. Пожалуйста, попробуйте еще раз.`,
    })
  }
}

function* toggleModeWorker({ type, payload }) {
  try {
    yield call(Api.toggleMode, payload)
    yield put({
      type: TOGGLE_MODE_SUCCESS,
      payload,
    })
  } catch (err) {
    yield put({
      type: TOGGLE_MODE_FAILURE,
      payload,
    })
  }
}



function* deleteAllFlatsWorker({ type, payload }) {
  try {
    yield call(Api.deleteAllFlats, payload)
    yield put({
      type: DELETE_ALL_FLATS_SUCCESS,
      payload,
    })
  } catch (err) {
    yield put({
      type: DELETE_ALL_FLATS_FAILURE,
      payload,
    })
  }
}

function* updateCodeCommentWorker({ type, payload }) {
  try {
    yield call(Api.updateCodeComment, payload)
    yield put({
      type: UPDATE_CODE_COMMENT_SUCCESS,
      payload: {
        deviceUuid: payload.intercomUuid,
      },
    })
  } catch (err) {
    yield put({
      type: UPDATE_CODE_COMMENT_FAILURE,
      payload,
    })
  }
}

function* uploadCodesButchWorker({ type, payload }) {
  try {
    yield put({ type: UPLOADING_CODES_REQUEST })
    yield call(Api.uploadCodesButch, payload)
    yield put({
      type: UPLOAD_CODES_BUTCH_SUCCESS,
      payload: {
        deviceUuid: payload.intercomUuid,
      },
    })
    yield put({ type: UPLOADING_CODES_SUCCESS })
  } catch (err) {
    yield put({
      type: UPLOAD_CODES_BUTCH_FAILURE,
      payload,
    })
    yield put({ type: UPLOADING_CODES_SUCCESS })
  }
}

function* downloadCodesWorker({ type, payload }) {
  try {
    put({ type: DOWNLOAD_CODES_LOADER_REQUEST })
    yield call(Api.downloadCodes, payload)
    yield put({ type: DOWNLOAD_CODES_LOADER_SUCCESS })
  } catch (err) {
    toastr.message('Что-то пошло не так!')
  }
}

export function* deviceActionsSagas() {
  yield takeLatest(FACTORY_RESET_REQUEST, factoryResetWorker)
  yield takeLatest(UPDATE_TIMEZONE_REQUEST, updateTimeZoneWorker)
  yield takeLatest(DELETE_ACCESS_CODE_REQUEST, deleteAccessCodeWorker)
  yield takeLatest(DELETE_ALL_ACCESS_CODES_REQUEST, deleteAllAccessCodesWorker)
  yield takeLatest(ADD_ACCESS_CODE_REQUEST, addAccessCodeWorker)
  yield takeLatest(GET_DEVICE_ACCESS_CODES_REQUEST, getDeviceAccessCodesWorker)
  yield takeLatest(
    UPDATE_GENERAL_FLAT_INFO_REQUEST,
    updateGeneralFlatsInfoWorker
  )
  yield takeLatest(UPDATE_SIP_CONFIG_REQUEST, updateSIPConfigWorker)
  yield takeLatest(UPDATE_SOUND_CONFIG_REQUEST, updateSoundConfigWorker)
  yield takeLatest(UPDATE_NETWORK_INFO_REQUEST, updateNetworkInfoWorker)
  yield takeLatest(UPDATE_DOOR_OPEN_TIMER_REQUEST, updateDoorOpenTimerWorker)
  yield takeLatest(OPEN_DOOR_REQUEST, openDoorWorker)
  yield takeLatest(OPEN_SECOND_DOOR_REQUEST, openSecondDoorWorker)
  yield takeLatest(START_EMERGENCY_REQUEST, startEmergencyWorker)
  yield takeLatest(RESET_INTERCOM_REQUEST, resetIntercomWorker)
  yield takeLatest(UPDATE_STANDART_SOUND_REQUEST, updateStandartSoundWorker)
  yield takeLatest(UPLOAD_SOUND_REQUEST, uploadSoundWorker)
  yield takeLatest(UPLOAD_FIRMWARE_REQUEST, uploadFirmwareWorker)
  yield takeLatest(ADD_KEY_REQUEST, addKeyWorker)
  yield takeLatest(REMOVE_KEY_REQUEST, removeKeyWorker)
  yield takeLatest(UPDATE_DEVICE_SOUND_REQUEST, updateDeviceSoundWorker)
  yield takeLatest(RESET_DEVICE_SOUNDS_REQUEST, resetDeviceSoundsWorker)
  yield takeLatest(GET_DEVICE_KEYS_COUNT_REQUEST, getDeviceKeysCountWorker)
  yield takeLatest(RESET_DEVICE_CAMERA_REQUEST, resetDeviceCameraWorker)
  yield takeLatest(DOWNLOAD_KEYS_REQUEST, downloadKeysWorker)
  yield takeLatest(GET_NET_INFO_REQUEST, getNetInfoWorker)
  yield takeLatest(GET_URL_INFO_REQUEST, getURLInfoWorker)
  yield takeLatest(LINE_TEST_REQUEST, lineTestWorker)
  yield takeLatest(TOGGLE_MODE_REQUEST, toggleModeWorker)
  yield takeLatest(TOGGLE_MODE_SUCCESS, getDeviceInfoShadowWorker)
  yield takeLatest(DELETE_ALL_FLATS_REQUEST, deleteAllFlatsWorker)
  yield takeLatest(DELETE_ALL_FLATS_SUCCESS, getDeviceInfoShadowWorker)
  yield takeLatest(
    UPDATE_OPTIONAL_BUTTON_REQUEST,
    updateOptionalButtonConfigWorker
  )
  yield takeLatest(UPDATE_CODE_COMMENT_REQUEST, updateCodeCommentWorker)
  yield takeLatest(UPDATE_CODE_COMMENT_SUCCESS, getDeviceAccessCodesWorker)
  yield takeLatest(UPLOAD_CODES_BUTCH_REQUEST, uploadCodesButchWorker)
  yield takeLatest(UPLOAD_CODES_BUTCH_SUCCESS, getDeviceAccessCodesWorker)
  yield takeLatest(DOWNLOAD_CODES_REQUEST, downloadCodesWorker)
  // yield takeLatest(UPDATE_GENERAL_FLAT_INFO_SUCCESS,updateAdcLevelsWorker)
}
