import { gql } from '@apollo/client'

// virtual && common
export const UPDATE_FLAT = gql`
  mutation UpdateIntercomFlats($input: IntercomFlatsInput!) {
    updateIntercomFlats(input: $input) {
      notice
      success
    }
  }
`
