import React from 'react'
import { Modal, Header, Button, Input, Form } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Field, Form as FormikForm } from 'formik'
import styled from 'styled-components'
import 'theme/slider.css'
import { useMutation } from '@apollo/client'
import { DELETE_VIRTUAL_FLAT } from 'gql/virtualFlats/mutation'
import { GET_VIRTUAL_FLATS } from 'gql/virtualFlats/query'
import { toastr } from 'react-redux-toastr'

const FormField = (props) => <Field as={Input} {...props} />

const MyForm = styled(Form)``

const schema = Yup.object().shape({
  flat: Yup.number().min(1).required(),
})

const initialValues = {
  flat: '',
}

export default function DeleteVirtualFlatModal({ open, onClose, ...props }) {
  const { id } = useParams()
  const [deleteFlat, { loading }] = useMutation(DELETE_VIRTUAL_FLAT, {
    refetchQueries: [
      {
        query: GET_VIRTUAL_FLATS,
        variables: {
          input: {
            uuid: id,
          },
        },
      },
    ],
  })

  const handleSubmit = async ({ flat }) => {
    await deleteFlat({
      variables: {
        input: {
          intercom: {
            uuid: id,
          },
          num: flat,
        },
      },
    }).then(() => toastr.success('Успех!'))

    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Header icon="trash alternate" content="Удалить квартиру" />
      <Modal.Content>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {({ values, handleChange }) => (
            <MyForm as={FormikForm}>
              <Form.Field>
                <FormField
                  label="Номер квартиры"
                  name="flat"
                  type="number"
                  onChange={handleChange}
                />
              </Form.Field>
              <Modal.Actions
                style={{
                  borderTop: '1px solid #d3d3d3',
                  marginTop: '1rem',
                  paddingTop: '1rem',
                }}
              >
                <Button onClick={onClose} circular>
                  Закрыть
                </Button>

                <Button
                  color="red"
                  circular
                  type="submit"
                  disabled={!values.flat || loading}
                  loading={loading}
                >
                  Удалить
                </Button>
              </Modal.Actions>
            </MyForm>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
