import React from 'react'
import { List, Icon } from 'semantic-ui-react'

const ListItemStyle = {
  padding: '1rem',
}

const Code = ({ value, description, onClick, exp_time, openCommentModal }) => (
  <List.Item style={ListItemStyle} onClick={openCommentModal}>
    <List.Content floated="right">
      действует{' '}
      {exp_time ? ` до ${new Date(exp_time).toLocaleString()}` : ' бессрочно'}
    </List.Content>

    <List.Icon
      verticalAlign="middle"
      name="remove circle"
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    />

    <List.Content>
      <List.Header>
        {value?.toString().length > 3 ? value : 0 + value}
      </List.Header>
      <List.Description>{description}</List.Description>
    </List.Content>
  </List.Item>
)

export default Code
