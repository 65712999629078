import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import Flat from './Flat'
import { GET_COMMON_FLATS, UPDATE_COMMON_FLATS } from 'gql/flats/query'
import FlatResistanceModal from 'components/modals/FlatResistanceModal'

export default function CommonFlats({ isIP, ID, pendingLineTest, lineTest, ...props }) {
  const { id } = useParams()
  const [state, setState] = useState({
    flat: null,
    modal: false,
  })
  const { data } = useQuery(GET_COMMON_FLATS, {
    variables: {
      input: {
        uuid: id,
      },
    },
  })
  const [updateFlats, info] = useMutation(UPDATE_COMMON_FLATS, {
    refetchQueries: [
      {
        query: GET_COMMON_FLATS,
        variables: {
          input: {
            uuid: id,
          },
        },
      },
    ],
  })

  const handleUpdateFlatInfo = async (flat) => {
    await updateFlats({
      variables: {
        input: {
          intercomID: flat.uuid,
          flats: [
            {
              flatNum: flat?.num,
              parameters: {
                analogSettings: {
                  alias: flat.analog_settings.alias,
                  thresholdCall: null,
                  blocked: flat.analog_settings?.blocked,
                  thresholdDoor: null,
                },
                blocked: flat?.blocked,
                redirection: Boolean(flat.redirection),
                sipAccountContact: flat?.sip_contact,
                soundVol: flat?.sound_vol,
              },
            },
          ],
        },
      },
    }).then(() => {
      toastr.success('Успех')
    })
  }

  const modalTrigger = (flat) => {
    setState({ modal: true, flat: flat })
  }

  const closeModal = () => {
    setState({ modal: false, flat: null })
  }

  return (
    <>
      <FlatResistanceModal
        open={state.modal}
        flat={state.flat}
        id={ID}
        isVirtual={false}
        onClose={closeModal}
      />
      <Table selectable color="pink">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="100%" textAlign="center">
              Квартиры
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Квартира</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Адрес SIP</Table.HeaderCell>
            {!isIP && (
              <>
                <Table.HeaderCell textAlign="center">Громкость звонка</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Переадресация аналоговой линии
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.intercom?.configShadow?.flats?.newFlats?.edges?.map(({ node: flat }, i) => {
            return (
              <Flat
                lineTest={lineTest}
                pendingLineTest={pendingLineTest}
                key={flat.num}
                id={ID}
                flat={flat}
                isVirtual={false}
                updateFlatInfo={handleUpdateFlatInfo}
                firstFlat={data?.intercom?.configShadow?.flats.firstFlat}
                lastFlat={data?.intercom?.configShadow?.flats.lastFlat}
                isIP={isIP}
                onClick={() => modalTrigger(flat)}
              />
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}
