import { combineReducers } from 'redux'
import devices from './devices'
import auth from './auth'
import device from './device'
import deviceActions from './deviceActions'
import ble from './ble'
import keys from './keys'
import { reducer as toastr } from 'react-redux-toastr'

import { all, fork, spawn } from 'redux-saga/effects'
import { authSaga } from './auth'
import { devicesSaga } from './devices'
import { deviceSagas } from './device'
import { deviceActionsSagas } from './deviceActions'
import { bleKeysSagas } from './ble'
import { keysSagas } from './keys'

export default combineReducers({
  auth,
  devices,
  device,
  deviceActions,
  toastr,
  ble,
  keys,
})

//fork for "mission critical" tasks, i.e. "if this task fails, please crash the whole app",
//and spawn for "not critical" tasks,
export function* rootSaga() {
  yield all([
    fork(authSaga),
    spawn(devicesSaga),
    spawn(deviceSagas),
    fork(deviceActionsSagas),
    fork(bleKeysSagas),
    fork(keysSagas),
  ])
}
