import React, { Component } from 'react'
import {
  Button,
  Icon,
  Accordion,
  Container,
  Form,
  Modal,
  Header,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { factoryReset } from 'ducks/deviceActions'
import wrapCategory from 'components/categories/wrapCategory'
import { red, lightRed } from 'theme.js'
import { withRouter } from 'react-router'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${red};
    }

    &.active {
      color: white !important;
      background: ${red};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }
`

class ResetSettings extends Component {
  state = {
    isOpen: false,
  }

  handleModalClose = () => {
    this.setState({ isOpen: false })
  }

  handleOpenModal = () => {
    this.setState({ isOpen: true })
  }

  handleFactoryReset = () => {
    this.props.factoryReset(this.props.match.params.id)
  }

  render() {
    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 'reset_settings'}
          index={'reset_settings'}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            Сброс настроек
            <Icon
              name={`angle ${
                this.props.activeIndex === 'reset_settings' ? 'down' : 'right'
              }`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 'reset_settings'}>
          <Container>
            <div
              style={{
                display: 'flex',
                // marginTop: '1.625rem',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                circular
                color="red"
                onClick={this.handleOpenModal}
                icon="repeat"
                content="Сбросить настройки домофона к заводским"
              />
            </div>
          </Container>
        </Accordion.Content>
        <Modal
          open={this.state.isOpen}
          onClose={this.handleModalClose}
          basic
          size="small"
        >
          <Header icon="warning" content="Внимание" />
          <Modal.Content>
            <h3>
              Данное действие приведет к сбросу ВСЕХ настроек домофона к
              заводским. Данное действие нельзя отменить.
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleModalClose} inverted circular>
              Отмена
            </Button>
            <Button
              onClick={() => {
                this.handleFactoryReset()
                this.handleModalClose()
              }}
              color="red"
              circular
            >
              Сбросить
            </Button>
          </Modal.Actions>
        </Modal>
      </Wrapper>
    )
  }
}

export default connect(null, { factoryReset })(
  withRouter(wrapCategory(ResetSettings))
)
