// 1921 100 638
export const formatDeviceId = (motherboardId = '') =>
  `${motherboardId.substr(0, 4)} ${motherboardId.substr(
    4,
    3
  )} ${motherboardId.substring(7)}`

export const chunk = (arr, chunkSize = 1, cache = []) => {
  if(!arr) return [] 
  const tmp = [...arr]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}
