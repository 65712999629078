import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  List,
  Container,
  Loader,
  Form,
  Message,
  Pagination,
  Button,
  Icon,
  Input,
  Popup,
  Header,
  ModalActions,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import {
  clearDeviceStatus,
  getDeviceAccessCodes,
  addAccessCode,
  deleteAccessCode,
  updateCodeComment,
  uploadCodesButch,
  downloadCodes,
  deleteAllAccessCodes,
} from 'ducks/deviceActions'
import Navbar from 'components/global/Navbar'
import MaskedInput from 'react-text-mask'
import AccessCode from 'components/listings/AccessCode'
import AddCommentModal from 'components/modals/AddComment'
import Dropzone from 'react-dropzone'
import { chunk } from 'helpers'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledLoader = styled(Loader)`
  &&& {
    &::before {
      /* border-color: white;  */
      border: none;
    }
    &::after {
      border-top-color: white;
      border-right-color: white;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
`

class AccessCodesPage extends Component {
  state = {
    keyValue: '',
    expTime: '',
    description: '',
    activePage: 1,
    // totalPages: 1,
    pageLimit: 10,
    confirmDeleteAll: false,
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
    this.props.getDeviceAccessCodes(match.params.id)
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      nextProps.getDeviceInfoShadow(nextProps.match.params.id)
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
      return { newKey: '', acceptedFile: null }
    }

    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (this.state.acceptedFile) {
      this.props.dispatch(
        uploadCodesButch(this.props.match.params.id, this.state.acceptedFile[0])
      )
    } else {
      this.props.addAccessCode({
        deviceUuid: this.props.match.params.id,
        key: this.state.keyValue,
        expTime: this.state.expTime,
        description: this.state.description,
      })
    }
  }

  handleDeleteClick = (keyValue) => {
    this.props.deleteAccessCode({
      deviceUuid: this.props.match.params.id,
      key: keyValue,
    })
  }

  handlePaginationChange = (e, { activePage }) => {
    // this.props.getDeviceAccessCodes(this.props.match.params.id, activePage)

    this.setState({ activePage })
  }

  deleteAllCodes = () => {
    this.setState({ confirmDeleteAll: false })
    this.props.deleteAllAccessCodes(this.props.match.params.id)
  }

  render() {
    const { match, accessCodes } = this.props
    const { pageLimit, activePage } = this.state
    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!accessCodes) {
      return (
        <div style={{ minHeight: '100vw' }}>
          <Loader active />
        </div>
      )
    }

    const viewData = chunk(this.props.accessCodes?.slice(0, 9999), pageLimit)[
      activePage - 1
    ] // ogranichenie 10k kodov

    return (
      <div>
        <Navbar />
        <Container style={{ marginTop: '4.625rem' }}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <>
                <Button
                  content={
                    <>
                      Скачать .csv с кодами доступа{' '}
                      {this.props?.loadingCodes && (
                        <Loader active size="mini" inline />
                      )}
                    </>
                  }
                  icon="cloud download"
                  labelPosition="right"
                  onClick={() => {
                    this.props.dispatch(
                      downloadCodes(this.props.match.params.id)
                    )
                  }}
                  type="button"
                />
                <Button
                  type={'button'}
                  content="Удалить все коды"
                  icon="trash"
                  color="red"
                  labelPosition="right"
                  onClick={() => {
                    this.setState({ confirmDeleteAll: true })
                  }}
                  circular
                />
              </>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Код"
                placeholder="трехзначный/пятизначный код"
                value={this.state.keyValue}
                onChange={this.onChange}
                name="keyValue"
              />

              <Form.Input
                label="Срок действия (опционально)"
                name="expTime"
                children={
                  <MaskedInput
                    name="expTime"
                    value={this.state.expTime}
                    onChange={this.onChange}
                    mask={[
                      /\d/,
                      /\d/,
                      '/',
                      /[0-1]/,
                      /[0-9]/,
                      '/',
                      '2',
                      '0',
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      ':',
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="01/09/2020 12:45"
                  />
                }
              />
            </Form.Group>
            <Form.Input
              name="description"
              label="Комментарий (опционально)"
              value={this.state.description}
              onChange={this.onChange}></Form.Input>

            <Form.Group>
              <Dropzone
                accept=".csv"
                multiple={false}
                onDrop={(accepted, rejected) => {
                  this.setState({
                    acceptedFile: accepted,
                    rejectedFile: rejected,
                    fileName: accepted[0].name,
                  })
                }}>
                {({ getInputProps, getRootProps }) => (
                  <Popup
                    content=".csv файл с ключами"
                    trigger={
                      <Button {...getRootProps()} type="button">
                        <input {...getInputProps()} />

                        <Icon
                          name="file outline alternate"
                          style={{ margin: 0 }}
                        />
                        {this.state.fileName || 'Файл'}
                      </Button>
                    }></Popup>
                )}
              </Dropzone>

              <Form.Button
                data-tooltip="Не более 10000 кодов"
                data-position="bottom center" // fake rtk
                type="submit"
                color="olive"
                fluid
                disabled={!!this.props?.upLoadingCodes}>
                Добавить{' '}
                {this.props?.upLoadingCodes && (
                  <StyledLoader
                    style={{
                      left: '5px',
                    }}
                    active
                    size="tiny"
                    inline
                  />
                )}
              </Form.Button>
            </Form.Group>
          </Form>

          <List selection size="big" style={{ marginTop: '4rem' }} divided>
            {accessCodes?.length < 1 && (
              <Message>
                <Message.Header>Отсутствуют коды доступа</Message.Header>
              </Message>
            )}
            {viewData?.map(({ value, exp_time, uuid, description }, idx) => (
              <AccessCode
                key={idx}
                value={value}
                exp_time={exp_time}
                description={description}
                onClick={() => this.handleDeleteClick(uuid)}
                openCommentModal={() =>
                  this.setState({
                    addCommentModal: {
                      uuid,
                      description,
                    },
                  })
                }
              />
            ))}
          </List>
          {this.props.accessCodes?.length > 1 && (
            <Grid centered style={{ paddingBottom: '1rem' }}>
              <Pagination
                onPageChange={this.handlePaginationChange}
                activePage={this.state.activePage}
                totalPages={Math.ceil(
                  this.props.accessCodes?.slice(0, 9999).length / pageLimit
                )}
              />
            </Grid>
          )}
        </Container>
        <AddCommentModal
          open={!!this.state.addCommentModal}
          uuid={this.state.addCommentModal}
          onClose={() => this.setState({ addCommentModal: false })}
          defaultValue={this.state.addCommentModal?.description}
          handleSubmit={(values) => {
            this.props.dispatch(
              updateCodeComment(this.props.match.params.id, {
                description: values.description,
                uuid: this.state.addCommentModal.uuid,
              })
            )
            this.setState({ addCommentModal: false })
          }}
        />
        <Modal
          open={this.state.confirmDeleteAll}
          onClose={() => this.setState({ confirmDeleteAll: false })}
          size="small"
          basic>
          <Header icon="warning" content="Внимание" />
          <Modal.Content>
            <h3>Данное действие приведет к удалению ВСЕХ кодов доступа</h3>
          </Modal.Content>
          <ModalActions>
            <Button
              onClick={() => this.setState({ confirmDeleteAll: false })}
              circular>
              Закрыть
            </Button>

            <Button onClick={this.deleteAllCodes} color="red" circular>
              Удалить
            </Button>
          </ModalActions>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ device, deviceActions }) => ({
  success: deviceActions.success,
  error: deviceActions.error,
  accessCodes: deviceActions.accessCodes,
  loadingCodes: deviceActions.loadingCodes,
  upLoadingCodes: deviceActions.upLoadingCodes,
  // totalPages: deviceActions.accessCodes?.total_pages,
})

export default connect(mapStateToProps, {
  getDeviceInfoShadow,
  clearDeviceStatus,
  getDeviceAccessCodes,
  addAccessCode,
  deleteAccessCode,
  deleteAllAccessCodes,
})(AccessCodesPage)
