import React, { useState } from 'react'
import { Modal, Header, Button, Input, Form } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Field, Form as FormikForm } from 'formik'
import styled from 'styled-components'
import { RIEInput } from 'riek'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'theme/slider.css'
import { pink } from 'theme'
import { useDispatch } from 'react-redux'
import { getDeviceInfoShadow } from 'ducks/device'
import { useMutation } from '@apollo/client'
import { ADD_VIRTUAL_FLAT } from 'gql/virtualFlats/mutation'
import { GET_VIRTUAL_FLATS } from 'gql/virtualFlats/query'
import { toastr } from 'react-redux-toastr'

const SliderWithTooltip = createSliderWithTooltip(Slider)

const FormField = (props) => <Field as={Input} {...props} />

const MyForm = styled(Form)``

const sipOptions = [
  // { key: '1', text: 'после звонка', value: 'after_call' },
  { key: '1', text: 'да', value: 'direct' },
  { key: '2', text: 'нет', value: 'none' },
]

const initialValues = {
  redirection: 'none',
  sound_vol: 100,
  // blocked: false,
  alias: null,
  num: null,
  // sip_contact: null,
}

const schema = Yup.object().shape({
  redirection: Yup.string(),
  sound_vol: Yup.number(),
  blocked: Yup.boolean(),
  alias: Yup.string().nullable(),
  num: Yup.number(),
})

export default function AddVirtualFlatModal({ open, onClose, ...props }) {
  const { id } = useParams()
  const [createFlats, { data, loading, error }] = useMutation(ADD_VIRTUAL_FLAT, {
    refetchQueries: [
      {
        query: GET_VIRTUAL_FLATS,
        variables: {
          input: {
            uuid: id,
          },
        },
      },
    ],
  })

  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    await createFlats({
      variables: {
        input: {
          intercomID: id,
          flats: [
            {
              flatNum: values?.num,
              parameters: {
                analogSettings: {
                  alias: Number(values.alias),
                  blocked: values?.blocked,
                },
                redirection: Boolean(values?.redirection),
                sipAccountContact: values?.sip_contact,
                soundVol: values?.sound_vol,
              },
            },
          ],
        },
      },
    }).then(() => {
      toastr.success('Успех')
      dispatch(getDeviceInfoShadow(id)) // refetch main flats
    })

    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Header icon="add" content="Добавить квартиру" />
      <Modal.Content>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <MyForm
              as={FormikForm}
              style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
            >
              <Form.Field>
                <label>Номер квартиры</label>
                <FormField name="num" fluid type="number" placeholder="№" />
              </Form.Field>
              {/* <Form.Field>
                <Checkbox
                  label="Блокировка квартиры"
                  value={values.blocked}
                  onChange={(e, option) => {
                    setFieldValue('blocked', option.checked)
                  }}
                />
              </Form.Field> */}
              <Form.Field>
                <label>Переадресация SIP</label>

                <Form.Select
                  fluid
                  options={sipOptions}
                  // as={}
                  name="redirection"
                  value={values.redirection}
                  onChange={(e, option) => {
                    setFieldValue('redirection', option.value)
                  }}
                />
              </Form.Field>

              <Form.Field>
                <label>Адрес переадресации</label>
                <FormField disabled={values.redirection !== 'direct'} name="sip_contact" fluid />
              </Form.Field>
              <Form.Field>
                <label>Громкость звонка</label>
                <Field
                  as={SliderWithTooltip}
                  trackStyle={{ backgroundColor: pink }}
                  value={values.sound_vol}
                  min={0}
                  max={100}
                  step={1}
                  name="sound_vol"
                  onChange={(vol) => setFieldValue('sound_vol', vol)}
                />
              </Form.Field>
              <Form.Field>
                <label>Переадресация аналоговой линии</label>
                <FormField label="На линию" name="alias" fluid />
              </Form.Field>
              <Modal.Actions
                style={{
                  borderTop: '1px solid #d3d3d3',
                  marginTop: '1rem',
                  paddingTop: '1rem',
                }}
              >
                <Button onClick={onClose} circular>
                  Закрыть
                </Button>

                <Button color="pink" circular type="submit" loading={loading}>
                  Создать
                </Button>
              </Modal.Actions>
            </MyForm>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
