import React, { Component } from 'react'
import {
  Grid,
  Button,
  Icon,
  Accordion,
  Container,
  Message,
  Checkbox,
} from 'semantic-ui-react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import wrapCategory from 'components/categories/wrapCategory'
import { purple, lightRed } from 'theme.js'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${purple};
    }

    &.active {
      color: white !important;
      background: ${purple};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }
`

// const options = [
//   { key: '1', text: '1', value: 1 },
//   { key: '2', text: '234', value: 234 },
// ]

class Firmware extends Component {
  state = {
    error: '',
    acceptedFile: [],
    rejectedFile: [],
    bluetoothFirmware: false,
  }

  onUpdate = e => false

  handleChange = (e, { value }) => this.setState({ selectedFirmware: value })

  handleClick = () => {
    if (this.state.acceptedFile.length < 1) {
      this.setState({ error: 'Выберите файл' })
    } else {
      this.props.uploadFirmware({
        uuid: this.props.id,
        file: this.state.acceptedFile[0],
        firmwareType: this.state.bluetoothFirmware ? 'bluetooth' : 'main',
      })
      this.setState({
        acceptedFile: [],
        rejectedFile: [],
        fileName: null,
        error: '',
      })
    }
  }

  render() {
    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 6}
          index={6}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            прошивка
            <Icon
              name={`angle ${this.props.activeIndex === 6 ? 'down' : 'right'}`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 6}>
          <Container>
            <Grid centered>
              <Grid.Row>Тип прошивки</Grid.Row>
              <Grid.Row>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    onClick={() => this.setState({ bluetoothFirmware: false })}
                  >
                    <Icon name="microchip" />
                    Основной модуль{' '}
                  </span>
                  <Checkbox
                    slider
                    style={{ margin: '0 1.325rem' }}
                    checked={this.state.bluetoothFirmware}
                    onChange={() =>
                      this.setState({
                        bluetoothFirmware: !this.state.bluetoothFirmware,
                      })
                    }
                  />{' '}
                  <span
                    onClick={() => this.setState({ bluetoothFirmware: true })}
                  >
                    <Icon name="bluetooth" />
                    Bluetooth модуль
                  </span>
                </div>
              </Grid.Row>
              <Grid.Row>
                <Dropzone
                  accept=".bin,.dex,.zip"
                  style={{
                    display: 'inline-block',
                    marginRight: '.625rem',
                  }}
                  multiple={false}
                  onDrop={(accepted, rejected) => {
                    this.setState({
                      acceptedFile: accepted,
                      rejectedFile: rejected,
                      fileName: accepted[0].name,
                    })
                  }}
                >
                  {({ getInputProps, getRootProps }) => (
                    <Button
                      circular
                      icon
                      labelPosition="left"
                      style={{
                        minWidth: '180px',
                        marginBottom: '1.625rem',
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <Icon name="file outline" />
                      {this.state.fileName
                        ? this.state.fileName
                        : 'Выбрать файл'}
                    </Button>
                  )}
                </Dropzone>
                <Button
                  circular
                  color="purple"
                  style={{ marginBottom: '1.625rem' }}
                  floated="right"
                  onClick={this.handleClick}
                >
                  Загрузить
                </Button>
              </Grid.Row>
              {this.state.error !== '' && this.state.acceptedFile.length < 1 && (
                <Message negative>
                  <p>{this.state.error}</p>
                </Message>
              )}
            </Grid>
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}

export default wrapCategory(Firmware)
