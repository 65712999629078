import { gql } from '@apollo/client'

export const ADD_VIRTUAL_FLAT = gql`
  mutation AddFlats($input: IntercomFlatsInput!) {
    addFlats(input: $input) {
      success
      notice
      intercom {
        IPMode
        collectingKeysState
        companyUUID
        isActivated
        isOnline
        isSipReg
        motherboardID
        uuid
        voiceText
      }
    }
  }
`

export const DELETE_VIRTUAL_FLAT = gql`
  mutation ($input: FlatNumberInput!) {
    deleteFlat(input: $input) {
      success
      notice
      intercom {
        IPMode
        collectingKeysState
        companyUUID
        isActivated
        isOnline
        isSipReg
        motherboardID
        uuid
        voiceText
      }
    }
  }
`
