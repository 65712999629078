import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Form,
  Input,
  Button,
  Popup,
  Checkbox,
  Icon,
} from 'semantic-ui-react'
import { updateNetworkInfo } from 'ducks/deviceActions'
import { amber } from 'theme'

class NetworkSettings extends Component {
  state = {
    staticIp: this.props.data.network_static_ip || '',
    gateway: this.props.data.network_gateway || '',
    subnet: this.props.data.network_subnet || '',
    dns: this.props.data.network_dns || '',
    mac: this.props.data.network_mac || '02:34:56:78:9A:BC',
    modified: false,
    staticIpValid: true,
    gatewayValid: true,
    subnetValid: true,
    dnsValid: true,
  }

  onSubmit = (e) => {
    const { staticIp, gateway, subnet, dns, mac, modified } = this.state

    const isStatic = this.state.staticIp && this.state.staticIp.length > 0

    if (isStatic) {
      // validate ip in network fields
      const errors = Object.entries(this.state).reduce((acc, entry) => {
        if (
          entry[0].search('Valid') === -1 && // if state name doesn`t include "Valid" string
          entry[0].search('modified') === -1 &&
          entry[0].search('mac') === -1 // or "mac"
        ) {
          // if (entry[1] !== '') {
          // if state`s value not empty
          if (!this.validate(entry[1])) {
            // if validation fails
            acc = true // set errors to true
            this.setState({
              [`${entry[0]}Valid`]: false,
            }) // show error to the user
          } else {
            this.setState({
              [`${entry[0]}Valid`]: true,
            }) //
          }
          // } else {
          //   this.setState({
          //     [`${
          //       entry[0] // validate value
          //     }Valid`]: true,
          //   }) // set state
          // }
        }
        return acc
      }, false)
      console.log(`${modified}  ${errors}`)

      if (modified && !errors) {
        this.props.updateNetworkInfo({
          uuid: this.props.deviceId,
          network_static_ip: staticIp,
          network_gateway: gateway,
          network_subnet: subnet,
          network_dns: dns,
          network_mac: mac,
        })
        this.setState({
          modified: false,
        })
      }
    } else {
      if (modified) {
        this.props.updateNetworkInfo({
          uuid: this.props.deviceId,
          network_static_ip: staticIp,
          network_gateway: '',
          network_subnet: '',
          network_dns: '',
          // network_mac: '',
        })
        this.setState({
          modified: false,
          staticIp: '',
          gateway: '',
          subnet: '',
          dns: '',
        })
      }
    }
  }

  validate = (value) => {
    const ipPattern = RegExp(
      '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
    )
    return ipPattern.test(value)
  }

  handleBlur = (props) => (e) => {
    const { value } = e.target

    this.setState({
      [`${props}Valid`]: value === '' || this.validate(value),
    })
  }

  onFocus = (props) => (e) => {
    this.setState({
      [`${props}Valid`]: true,
    })
  }

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, modified: true })

  handleUnCheck = () => {
    this.setState({
      staticIp: '',
      gateway: '',
      subnet: '',
      dns: '',
      staticIpValid: true,
      gatewayValid: true,
      subnetValid: true,
      dnsValid: true,
      modified: true,
    })
  }

  render() {
    const {
      staticIp,
      gateway,
      subnet,
      dns,
      mac,
      staticIpValid,
      dnsValid,
      gatewayValid,
      subnetValid,
    } = this.state

    const isStatic = this.state.staticIp && this.state.staticIp.length > 0
    return (
      <Grid.Column>
        <Grid.Row>
          <div
            onClick={() => {
              isStatic && this.handleUnCheck()
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '2rem',
            }}
          >
            <span>DHCP </span>
            <Checkbox
              slider
              style={{ margin: '0 1.325rem' }}
              checked={isStatic}
            />{' '}
            <span>Static IP</span>
          </div>
        </Grid.Row>
        <Form>
          <Form.Group widths="equal">
            <Form.Field error={!staticIpValid}>
              <label>Static IP</label>
              <Input
                placeholder="127.0.0.1"
                name="staticIp"
                onFocus={this.onFocus('staticIp')}
                value={staticIp}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field error={!gatewayValid} disabled={!isStatic}>
              <label>Gateway</label>
              <Popup
                trigger={
                  <Input
                    placeholder="127.0.0.1"
                    name="gateway"
                    onBlur={this.handleBlur('gateway')}
                    onFocus={this.onFocus('gateway')}
                    value={gateway}
                    onChange={this.onChange}
                  />
                }
                content={`Невалидный IP адрес. Пример: 192.162.1.1`}
                open={!gatewayValid}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={!subnetValid} disabled={!isStatic}>
              <label>Subnet</label>
              <Popup
                trigger={
                  <Input
                    placeholder="127.0.0.1"
                    name="subnet"
                    onBlur={this.handleBlur('subnet')}
                    onFocus={this.onFocus('subnet')}
                    value={subnet}
                    onChange={this.onChange}
                  />
                }
                content={`Невалидный IP адрес. Пример: 192.162.1.1`}
                open={!subnetValid}
              />
            </Form.Field>
            <Form.Field error={!dnsValid} disabled={!isStatic}>
              <label>DNS address</label>
              <Popup
                trigger={
                  <Input
                    placeholder="127.0.0.1"
                    name="dns"
                    onBlur={this.handleBlur('dns')}
                    onFocus={this.onFocus('dns')}
                    value={dns}
                    onChange={this.onChange}
                  />
                }
                content={`Невалидный IP адрес. Пример: 192.162.1.1`}
                open={!dnsValid}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>MAC address</label>
            <Input
              placeholder="00:26:57:00:1f:02"
              name="mac"
              value={mac}
            />
          </Form.Field>
          <Form.Field>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flex: 1,
              }}
            >
              <Button
                circular
                style={{
                  background: amber,
                  color: 'white',
                }}
                onClick={this.onSubmit}
                className="update-button"
                name="submit"
                ref={(submit) => (this.submit = submit)}
                disabled={!this.state.modified}
              >
                Обновить данные
              </Button>
            </div>
          </Form.Field>
        </Form>
      </Grid.Column>
    )
  }
}

const mapStateToProps = (state) => ({
  data:
    state.device.shadowDevice.properties &&
    state.device.shadowDevice.properties.base,
  deviceId: state.device.shadowDevice.device_id,
})

export default connect(mapStateToProps, { updateNetworkInfo })(NetworkSettings)
