import { Fragment } from 'react'
import { Container } from 'semantic-ui-react'
import Navbar from './Navbar'

export default function EmptyLoad() {
  return (
    <Fragment>
      <Navbar />
      <Container style={{ marginTop: '4.625rem' }}>
        Тут пока ничего нет
      </Container>
    </Fragment>
  )
}
