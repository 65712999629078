import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Label,
  Grid,
  Form,
  Input,
  Segment,
  Button,
  Header,
  Dropdown,
  Checkbox,
  Modal,
} from 'semantic-ui-react'
import { updateGeneralFlatsInfo, toggleMode } from 'ducks/deviceActions'

const center = {
  display: 'flex',
  justifyContent: 'center',
}

const comOptions = [
  {
    key: 0,
    text: 'Метаком',
    value: 0,
  },
  {
    key: 1,
    text: 'Визит',
    value: 1,
  },
  {
    key: 2,
    text: 'Цифрал',
    value: 2,
  },
  {
    key: 3,
    text: 'Элтис',
    value: 3,
  },
  {
    key: 4,
    text: 'Daxis',
    value: 4,
  },
]

class FlatsGeneral extends Component {
  state = {
    firstFlat: this.props.data.flats_first,
    lastFlat: this.props.data.flats_last,
    collectingKeys: this.props.data.collecting_keys,
    extCom: this.props.data.ext_com,
    offset: this.props.data.flat_offset,
    modified: false,
    modifiedMode: false,
    isIP: this.props.isIP,
    confirmModalOpen: false,
  }

  onSubmit = (e, element, ignoreModifiedModeCheck = false) => {
    if (
      !ignoreModifiedModeCheck &&
      this.state.modifiedMode &&
      this.state.isIP === true
    ) {
      return this.handleModalOpen()
    }
    if (this.state.modifiedMode) {
      this.props.toggleMode(this.props.id)
    }

    this.props.updateGeneralFlatsInfo({
      id: this.props.id,
      firstFlat: this.state.firstFlat,
      lastFlat: this.state.lastFlat,
      extCom: this.state.extCom,
      offset: this.state.offset,
    })
    this.setState({
      modified: false,
      modifiedMode: false,
      confirmModalOpen: false,
    })
  }

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, modified: true })

  onExtComChange = (e, { value }) =>
    this.setState({ extCom: value, modified: true })

  handleModalOpen = () => this.setState({ confirmModalOpen: true })

  handleModalClose = () => this.setState({ confirmModalOpen: false })

  render() {
    const { firstFlat, lastFlat, extCom, offset } = this.state
    const { style, ...props } = this.props

    return (
      <Segment style={{ display: 'flex', flexDirection: 'column', ...style }}>
        <Modal
          // trigger={<Button onClick={this.handleModalOpen}>Show Modal</Button>}
          open={this.state.confirmModalOpen}
          onClose={this.handleModalClose}
          basic
          size="small">
          <Header icon="warning" content="Внимание" />
          <Modal.Content>
            <h3>
              Отключая поддержку координатно-матричной линии вы отключаете
              поддержку работы с коммутатором и аналоговыми трубками
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleModalClose} inverted circular>
              Отмена
            </Button>
            <Button
              onClick={() => this.onSubmit(null, null, true)}
              color="red"
              circular>
              Все равно отключить
            </Button>
          </Modal.Actions>
        </Modal>

        <Header>Квартиры</Header>
        {/* <div style={{ ...center, flexDirection: 'column' }}> */}
        <Form
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Form.Field>
            <Checkbox
              label="Координатно-матричная линия"
              name="mode"
              onChange={(e, { checked }) =>
                this.setState({
                  isIP: !this.state.isIP,
                  modifiedMode: true,
                  modified: true,
                })
              }
              checked={!this.state.isIP}
            />
          </Form.Field>
          {!this.state.isIP && (
            <>
              <Form.Field>
                <label>Первая квартира</label>
                <Input
                  placeholder="Первая квартира"
                  size="mini"
                  name="firstFlat"
                  type="number"
                  value={firstFlat}
                  defaultValue={this.props.data.first_flat}
                  onChange={this.onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Последняя квартира</label>
                <Input
                  placeholder="Последняя квартира"
                  size="mini"
                  name="lastFlat"
                  type="number"
                  value={lastFlat}
                  onChange={this.onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Смещение</label>
                <Input
                  placeholder="Смещение"
                  size="mini"
                  name="offset"
                  type="number"
                  value={offset}
                  onChange={this.onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Тип коммутатора</label>
                <Dropdown
                  value={extCom}
                  onChange={this.onExtComChange}
                  name="extCom"
                  size="mini"
                  selection
                  options={comOptions}
                  style={{
                    lineHeight: '1.21428571em',
                    padding: '0.67857143em 1em',
                    fontSize: '0.78em',
                  }}
                />
              </Form.Field>
            </>
          )}

          <Form.Button
            circular
            color="black"
            onClick={this.onSubmit}
            className="update-button"
            disabled={!this.state.modified}>
            Обновить
          </Form.Button>
        </Form>
        {/* </div> */}
      </Segment>
    )
  }
}

const mapStateToProps = ({ device }) => ({
  data:
    device &&
    device.shadowDevice &&
    device.shadowDevice.properties &&
    device.shadowDevice.properties.base,
  id: device && device.shadowDevice && device.shadowDevice.device_id,
  isIP: device?.shadowDevice?.mode === 'ip',
})

export default connect(mapStateToProps, { updateGeneralFlatsInfo, toggleMode })(
  FlatsGeneral
)
