import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Header, Icon, Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { getDeviceInfo, getDeviceInfoShadow } from 'ducks/device'

const propNames = {
  base: 'Общие настройки',
  flats_config: 'Квартиры',
  sip_credentials: 'Настройки  SIP',
  social: 'Социальное информирование',
  poll: 'Опросы',
  emergency: 'Оповещения о ЧС',
  sound_config: 'Настройки звука',
  standard_sounds: 'Стандартные звуки',
  keys_config: 'Ключи',
  digital_keys_config: 'Цифровые коды',
}

const Wrapper = styled.div`
  & > *:nth-child(2) {
    padding: 0.625rem;
  }
`

const UnsyncWrapper = styled(Wrapper)`
  background: white;
  border: 2px solid black;
  border-radius: 10px;
`

const Title = styled.div`
  background: black;
  color: white;
  /* height: 1.625rem; */
  padding: 0.625rem;
  display: flex;
  justify-content: center;
`

class SyncStatus extends React.Component {
  state = {
    unsync: [],
  }

  componentDidMount() {
    this.checkSyncStatus()
    this._updateIntervalId = window.setInterval(this.updateDeviceInfo, 3000)
  }

  componentWillUnmount() {
    clearInterval(this._updateIntervalId)
  }

  updateDeviceInfo = async () => {
    const { match, history, dispatch } = this.props
    await Promise.all([
      dispatch(getDeviceInfo(match.params.id, history)),
      dispatch(getDeviceInfoShadow(match.params.id)),
    ])
    this.checkSyncStatus()
  }

  checkSyncStatus = () => {
    const { currentDevice, shadowDevice } = this.props

    const currentProps = Object.entries(currentDevice.properties).map(
      entry => ({
        name: entry[0],
        checksum: entry[1].checksum,
      }),
    )
    const shadowProps = Object.entries(shadowDevice.properties).map(entry => ({
      name: entry[0],
      checksum: entry[1].checksum,
    }))

    const unsync = currentProps.filter(
      (prop, ind) => prop.checksum !== shadowProps[ind].checksum,
    )
    this.setState({ unsync })
  }

  render() {
    const { unsync } = this.state

    return unsync.length > 0 ? (
      <UnsyncWrapper>
        <Title>Статус синхронизации</Title>
        <div>
          {unsync.map(prop => (
            <Header.Subheader key={prop.name}>
              <Icon
                name="refresh"
                style={{ marginRight: '.625rem', color: 'red' }}
              />
              {propNames[prop.name]}
            </Header.Subheader>
          ))}
        </div>
      </UnsyncWrapper>
    ) : (
      <Button
        style={{
          marginLeft: 'auto',
          marginBottom: '0.625rem',
          background: 'black',
        }}
        icon
        labelPosition="left"
        color="black"
        circular
      >
        <Icon
          name="checkmark"
          style={{ marginRight: '.625rem' }}
          color="green"
        />
        Устройство синхронизировано
      </Button>
    )
  }
}

const mapStateToProps = ({ device: { currentDevice, shadowDevice } }) => ({
  currentDevice,
  shadowDevice,
})

export default connect(mapStateToProps)(withRouter(SyncStatus))
