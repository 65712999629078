import React, { useState } from 'react'
import {
  Modal,
  Header,
  Button,
  Icon,
  Table,
  Popup,
  Input,
  Form,
  Dropdown,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Field, Form as FormikForm } from 'formik'
import styled from 'styled-components'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'theme/slider.css'
import { pink } from 'theme'
import { useDispatch } from 'react-redux'
import {
  updateFlatInfo,
  clearDeviceStatus,
  lineTest,
} from 'ducks/deviceActions'
import { getDeviceInfoShadow } from 'ducks/device'

const SliderWithTooltip = createSliderWithTooltip(Slider)

const FormField = (props) => <Field as={TextArea} {...props} />

const MyForm = styled(Form)``

const sipOptions = [
  // { key: '1', text: 'после звонка', value: 'after_call' },
  { key: '1', text: 'да', value: 'direct' },
  { key: '2', text: 'нет', value: 'none' },
]

const initialValues = {
  redirection: 'none',
  sound_vol: 100,
  blocked: false,
  alias: null,
}

const schema = Yup.object().shape({
  description: Yup.string(),
})

export default function AddCommentModal({
  handleSubmit,
  open,
  onClose,
  defaultValue,
  ...props
}) {
  const dispatch = useDispatch()

  return (
    <Modal
      // trigger={<Button onClick={this.handleModalOpen}>Show Modal</Button>}
      open={open}
      onClose={onClose}
      // basic
      size="small">
      <Header content="Добавить комментарий" />
      <Modal.Content>
        <Formik
          initialValues={{
            description: defaultValue,
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}>
          {({ values, setFieldValue, handleReset, handleSubmit }) => (
            <MyForm onReset={handleReset} onSubmit={handleSubmit}>
              <FormField placeholder="Комментарий" name="description" fluid />

              <Modal.Actions
                style={{
                  borderTop: '1px solid #d3d3d3',
                  marginTop: '1rem',
                  paddingTop: '1rem',
                }}>
                <Button onClick={onClose} circular>
                  Закрыть
                </Button>

                <Button color="blue" circular type="submit">
                  Сохранить
                </Button>
              </Modal.Actions>
            </MyForm>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
