import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import registerServiceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import ReduxToastr, { toastr } from 'react-redux-toastr'
import 'theme/semantic.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'index.css'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
const store = configureStore()

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      toastr.error('Что-то пошло не так...', message)
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = createHttpLink({
  uri: 'https://api.sputnik.systems/query',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = JSON.parse(localStorage.getItem('devicesAppState'))
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      Authorization: token ? `Bearer ${token.auth.jwt}` : '',
    },
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
})


//2125129189
const Root = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Fragment>
          <App />
          <ReduxToastr preventDuplicates position="top-right" progressBar />
        </Fragment>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

const showUpdateToast = (action) =>
  toastr.info('Доступна новая версия', 'Нажмите для обновления', {
    onToastrClick: action,
  })
registerServiceWorker(showUpdateToast)
