const { gql } = require('@apollo/client')

export const GET_VIRTUAL_FLATS = gql`
  query GetFlatsList($input: IntercomFilter!) {
    intercom(input: $input) {
      configShadow {
        flats {
          version
          newFlats(limit: 1000, filter: VIRTUAL) {
            edges {
              node {
                num
                blocked
                redirectionBool
                soundVol
                sipAccountContact
                analogSettings {
                  alias
                  blocked
                  thresholdCall
                }
              }
            }
          }
          firstFlat
          lastFlat
        }
      }
    }
  }
`
