import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  List,
  Container,
  Loader,
  Form,
  Message,
  Pagination,
  Button,
  Icon,
  Input,
  Popup,
  Checkbox,
  Card,
  Modal,
  Header,
  ModalActions,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import {
  addKey,
  clearDeviceStatus,
  removeKey,
  downloadKeys,
} from 'ducks/deviceActions'
import Dropzone from 'react-dropzone'
import Key from 'components/listings/Key'
import Navbar from 'components/global/Navbar'
import AddCommentModal from 'components/modals/AddComment'
import {
  fetchKeys,
  updateSecuredKeysRequest,
  createKey,
  updateKeyComment,
  uploadButch,
  deleteAllKeys,
} from 'ducks/keys'
import { chunk } from 'helpers'
import styled from 'styled-components'

const StyledLoader = styled(Loader)`
  &&& {
    &::before {
      /* border-color: white;  */
      border: none;
    }
    &::after {
      border-top-color: white;
      border-right-color: white;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
`
const KEYS_PER_PAGE = 10

class KeysPage extends Component {
  state = {
    newKey: '',
    addKeyCommentModal: false,
    secured_keys_mode: this.props.securedKeysMode,
    aes_key: this.props.securedKeysData?.aes_key || '',
    sector_number: this.props.securedKeysData?.sector_number || '',
    block_number: this.props.securedKeysData?.block_number || '',
    activePage: 1,
    pageLimit: 10,
    confirmDeleteAll: false,
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
    this.props.dispatch(
      fetchKeys(match.params.id, { page: this.state.activePage })
    )
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      nextProps.getDeviceInfoShadow(nextProps.match.params.id)
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
      return { newKey: '', description: '', acceptedFile: null }
    }

    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }

    return prevState
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.keys !== prevProps.keys) {
      this.setState({
        currentKeys: this.props.keys,
      })
    }
    if (this.props.securedKeysData !== prevProps.securedKeysData) {
      this.setState({
        aes_key: this.props.securedKeysData?.aes_key,
        sector_number: this.props.securedKeysData?.sector_number,
        block_number: this.props.securedKeysData?.block_number,
      })
    }

    if (
      this.props.securedKeysMode !== prevProps.securedKeysMode &&
      !this.state.secured_keys_mode
    ) {
      this.setState({
        secured_keys_mode: this.props.securedKeysMode,
      })
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onAdd = (e) => {
    const { match } = this.props
    console.log(this.state.acceptedFile)
    if (this.state.acceptedFile) {
      console.log('')
      this.props.dispatch(
        uploadButch(match.params.id, this.state.acceptedFile[0])
      )
    } else {
      this.props.dispatch(
        createKey(match.params.id, {
          key_data: this.state.newKey,
          description: this.state.description,
        })
      )
    }
  }

  downloadKeys = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.props.downloadKeys(id)
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value })

  handlePaginationChange = (e, { activePage }) => {
    // this.props.dispatch(
    //   fetchKeys(this.props.match.params.id, { page: activePage })
    // )
    this.setState({ activePage })
  }

  deleteAllKeys = () => {
    this.setState({ confirmDeleteAll: false })
    this.props.deleteAllKeys(this.props.match.params.id)
  }

  render() {
    const { match, collectingKeys, secured_keys_base_data } = this.props
    const { currentKeys, activePage, pageLimit } = this.state

    const viewData = chunk(currentKeys, pageLimit)[activePage - 1]
    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!collectingKeys && !currentKeys) {
      return (
        <div style={{ minHeight: '100vw' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <div>
        <Navbar />
        <Container style={{ minHeight: '100vh', marginTop: '4.625rem' }}>
          <Grid>
            <Grid.Row centered>
              <Grid.Column textAlign="left">
                {!collectingKeys && (
                  <>
                    <Button
                      content={
                        <>
                          Скачать .csv с ключами{' '}
                          {this.props.loadingKeys && (
                            <Loader active size="mini" inline />
                          )}
                        </>
                      }
                      icon="cloud download"
                      labelPosition="right"
                      onClick={this.downloadKeys}
                      // circular
                    />
                    <Button
                      type={'button'}
                      content="Удалить все ключи"
                      icon="trash"
                      color="red"
                      labelPosition="right"
                      onClick={() => {
                        this.setState({ confirmDeleteAll: true })
                      }}
                      circular
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
            {collectingKeys ? (
              <Grid.Row centered>
                <Message negative>
                  <Message.Header>
                    В данный момент включен автосбор. Для добавления и удаления
                    ключей выключите Автосбор
                  </Message.Header>
                </Message>
              </Grid.Row>
            ) : (
              <React.Fragment>
                <Grid.Row centered>
                  <Grid.Column>
                    <Form onSubmit={this.onAdd}>
                      <Card fluid style={{ padding: '1rem' }}>
                        <Form.Field>
                          <Checkbox
                            toggle
                            label="Безопасные ключи"
                            color="blue"
                            name="secured_keys_mode"
                            checked={this.state.secured_keys_mode}
                            onChange={(e, { checked }) => {
                              this.setState({ secured_keys_mode: checked })
                              if (!checked) {
                                this.props.updateSecuredKeysRequest(
                                  this.props.match.params.id,
                                  { secured_keys_mode: checked }
                                )
                              }
                            }}></Checkbox>
                        </Form.Field>
                        {this.state.secured_keys_mode && (
                          <>
                            <Form.Group widths="equal">
                              <Form.Field>
                                <Form.Input
                                  label="AES ключ"
                                  placeholder={
                                    this.props.securedKeysMode &&
                                    !this.state.aes_key
                                      ? "*********"
                                      : '16 байт'
                                  } // 16 байт
                                  name="aes_key"
                                  value={this.state.aes_key}
                                  onChange={this.onChange}></Form.Input>
                              </Form.Field>
                              <Form.Field>
                                <Form.Input
                                  name="sector_number"
                                  label="Номер сектора"
                                  placeholder="1-32"
                                  value={this.state.sector_number}
                                  onChange={this.onChange}></Form.Input>
                              </Form.Field>
                              <Form.Field>
                                <Form.Input
                                  name="block_number"
                                  label="Номер блока внутри сектора"
                                  placeholder="1-3"
                                  value={this.state.block_number}
                                  onChange={this.onChange}></Form.Input>
                              </Form.Field>
                            </Form.Group>
                            <Form.Button
                              style={{ width: 'fit-content' }}
                              type="button"
                              onClick={() => {
                                this.props.updateSecuredKeysRequest(
                                  this.props.match.params.id,
                                  {
                                    secured_keys_mode:
                                      this.state.secured_keys_mode,
                                    aes_key: this.state.aes_key,
                                    sector_number: this.state.sector_number,
                                    block_number: this.state.block_number,
                                  }
                                )
                              }}>
                              Сохранить
                            </Form.Button>
                          </>
                        )}
                      </Card>
                      <Card fluid style={{ padding: '1rem' }}>
                        <h4>Добавить ключ</h4>
                        <Form.Field>
                          <Input
                            name="newKey"
                            placeholder="hex"
                            value={this.state.newKey}
                            onChange={this.onChange}
                            width={16}
                            action={
                              <>
                                <Dropzone
                                  accept=".csv"
                                  multiple={false}
                                  onDrop={(accepted, rejected) => {
                                    this.setState({
                                      acceptedFile: accepted,
                                      rejectedFile: rejected,
                                      fileName: accepted[0].name,
                                    })
                                  }}>
                                  {({ getInputProps, getRootProps }) => (
                                    <Popup
                                      content=".csv файл с ключами"
                                      trigger={
                                        <Button circular {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <Icon
                                            name="file outline alternate"
                                            style={{ margin: 0 }}
                                          />
                                          {this.state.fileName}
                                        </Button>
                                      }></Popup>
                                  )}
                                </Dropzone>
                              </>
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <Input
                            name="description"
                            placeholder="Описание"
                            value={this.state.description}
                            onChange={this.onChange}></Input>
                        </Form.Field>
                        <Form.Button
                          type="submit"
                          color="blue"
                          disabled={!!this.props?.upLoadingKeys}>
                          Добавить{' '}
                          {this.props?.upLoadingKeys && (
                            <StyledLoader
                              style={{
                                left: '5px',
                              }}
                              active
                              size="tiny"
                              inline
                            />
                          )}
                        </Form.Button>
                      </Card>
                    </Form>

                    <List selection size="big">
                      {currentKeys?.length < 1 && (
                        <Message>
                          <Message.Header>Отсутствуют ключи</Message.Header>
                        </Message>
                      )}
                      {viewData?.map((data, ind) => (
                        <Key
                          key={ind}
                          uuid={match.params.id}
                          onClick={this.props.removeKey}
                          openCommentModal={() =>
                            this.setState({
                              addKeyCommentModal: data,
                            })
                          }
                          {...data}
                        />
                      ))}
                    </List>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}
            {currentKeys?.length > 1 && (
              <Grid.Row centered style={{ paddingBottom: '1rem' }}>
                <Pagination
                  activePage={this.state.activePage}
                  onPageChange={this.handlePaginationChange}
                  totalPages={Math.ceil(currentKeys?.length / pageLimit)}
                  // boundaryRange={0}
                />
              </Grid.Row>
            )}
          </Grid>
        </Container>
        <AddCommentModal
          open={!!this.state.addKeyCommentModal}
          onClose={() => this.setState({ addKeyCommentModal: false })}
          defaultValue={this.state.addKeyCommentModal?.description}
          handleSubmit={(values) => {
            this.props.dispatch(
              updateKeyComment(this.props.match.params.id, {
                description: values.description,
                key_data: this.state.addKeyCommentModal.key_data,
              })
            )
            this.setState({ addKeyCommentModal: false })
          }}
        />
        <Modal
          open={this.state.confirmDeleteAll}
          onClose={() => this.setState({ confirmDeleteAll: false })}
          basic
          size="small">
          <Header icon="warning" content="Внимание" />
          <Modal.Content>
            <h3>Данное действие приведет к удалению ВСЕХ ключей</h3>
          </Modal.Content>
          <ModalActions>
            <Button
              onClick={() => this.setState({ confirmDeleteAll: false })}
              circular>
              Отмена
            </Button>

            <Button onClick={this.deleteAllKeys} color="red" circular>
              Удалить
            </Button>
          </ModalActions>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ device, deviceActions, keys }) => ({
  keys: keys.list?.data, //.keys,
  totalPages: 1, // keys.list?.data.total_pages,
  success: deviceActions.success,
  error: deviceActions.error,
  loadingKeys: deviceActions.loadingKeys,
  upLoadingKeys: keys.upLoadingKeys,
  collectingKeys:
    device.shadowDevice.properties &&
    device.shadowDevice.properties.base.collecting_keys,
  securedKeysMode: device.shadowDevice?.properties?.base?.secured_keys_mode,
  securedKeysData:
    device.shadowDevice?.properties?.base?.secured_keys_base_data,
})

export default connect(mapStateToProps, {
  getDeviceInfoShadow,
  addKey,
  clearDeviceStatus,
  removeKey,
  downloadKeys,
  updateSecuredKeysRequest,
  uploadButch,
  deleteAllKeys,
})(KeysPage)
